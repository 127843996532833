export const ActionTypes = {
    GET_CAMPAIGN_LIST: "GET_CAMPAIGN_LIST",
    GET_CAMPAIGN_LIST_FOR_SEQUENCE: "GET_CAMPAIGN_LIST_FOR_SEQUENCE",
    ADD_CAMPAIGN_NEW_LIST: "ADD_CAMPAIGN_NEW_LIST",
    CAMPAIGN_CURRENT_TAB: "CAMPAIGN_CURRENT_TAB",
    GET_CAMPAIGN_LIST_ID_WISE: "GET_CAMPAIGN_LIST_ID_WISE",
    DELETE_CAMPAIGN_LIST: "DELETE_CAMPAIGN_LIST",
    UPDATE_CAMPAIGN_NEW_LIST: "UPDATE_CAMPAIGN_NEW_LIST",
    SET_STORE_CAMPAIGN_LIST_OPTION: "SET_STORE_CAMPAIGN_LIST_OPTION",
    SET_STORE_UPDATE_CAMPAIGN_SEQUENCE:"SET_STORE_UPDATE_CAMPAIGN_SEQUENCE",
    SET_STORE_MAIL_CAMPAIGN_LIST:"SET_STORE_MAIL_CAMPAIGN_LIST",
    SET_STORE_MOSAIC_LIST:"SET_STORE_MOSAIC_LIST",
    SET_STORE_SPONSORS_LIST_DATA:"SET_STORE_SPONSORS_LIST_DATA",
    SET_STORE_BLOG_LIST_DATA:"SET_STORE_BLOG_LIST_DATA",
    SET_STORE_UPDATE_BLOG_DETAILS:"SET_STORE_UPDATE_BLOG_DETAILS",
    CAMPAIGN_LIST_APPROVAL:"CAMPAIGN_LIST_APPROVAL",
    CAMPAIGN_LIST_UPDATE_APPROVAL:"CAMPAIGN_LIST_UPDATE_APPROVAL",
    CAMPAIGN_LIST_AFTER_APPROVAL:"CAMPAIGN_LIST_AFTER_APPROVAL",
    MOSAIC_REQUEST_LIST:"MOSAIC_REQUEST_LIST",
    WRAP_UP_REQUEST_LIST:"WRAP_UP_REQUEST_LIST",
    WRAP_UP_REQUEST_LIST_COMPLETE:"WRAP_UP_REQUEST_LIST_COMPLETE",
    PAYOUTS_LIST:"PAYOUTS_LIST",


};

export const SelectCampaignTab = (urls) => {
    return {
       type: ActionTypes.CAMPAIGN_CURRENT_TAB,
       payload: urls,
    }
};

export const getCampaignList = (list) => {
    return {
       type: ActionTypes.GET_CAMPAIGN_LIST,
       payload: list,
    }
};
export const getCompaignListForSequence = (list) => {
    return {
       type: ActionTypes.GET_CAMPAIGN_LIST_FOR_SEQUENCE,
       payload: list,
    }
};

export const setStoreMailCampaignList = (list) => {
    return {
       type: ActionTypes.SET_STORE_MAIL_CAMPAIGN_LIST,
       payload: list,
    }
};
export const setStoreMosaicList = (list) => {
    return {
       type: ActionTypes.SET_STORE_MOSAIC_LIST,
       payload: list,
    }
};
export const setStoreUpdateCampaignSequence = (list) => {
    return {
       type: ActionTypes.SET_STORE_UPDATE_CAMPAIGN_SEQUENCE,
       payload: list,
    }
};

export const AddCampaignList = (Campaign) => {
    return {
        type: ActionTypes.ADD_CAMPAIGN_NEW_LIST,
        payload: Campaign,
    }
};

export const UpdateCampaignX = (Campaign) => {
    return {
        type: ActionTypes.UPDATE_CAMPAIGN_NEW_LIST,
        payload: Campaign,
    }
};

export const GetCampaignListIDWise = (Campaign) => {
    return {
        type: ActionTypes.GET_CAMPAIGN_LIST_ID_WISE,
        payload: Campaign,
    }
};

export const DeleteCampaignList = (Campaign) => {
    return {
        type: ActionTypes.DELETE_CAMPAIGN_LIST,
        payload: Campaign,
    }
};
export const setStoreCampaignListOption = (Campaign) => {
    return {
        type: ActionTypes.SET_STORE_CAMPAIGN_LIST_OPTION,
        payload: Campaign,
    }
};
export const setStoreSponsorsListData = (list) => {
    return {
       type: ActionTypes.SET_STORE_SPONSORS_LIST_DATA,
       payload: list,
    }
};
export const setStoreBlogListData = (list) => {
    return {
       type: ActionTypes.SET_STORE_BLOG_LIST_DATA,
       payload: list,
    }
};
export const setStoreUpdateBlogDetails = (list) => {
    return {
       type: ActionTypes.SET_STORE_UPDATE_BLOG_DETAILS,
       payload: list,
    }
};
export const getCampaignListForApproval = (list) => {
    return {
       type: ActionTypes.CAMPAIGN_LIST_APPROVAL,
       payload: list,
    }
};
export const getCampaignListForUpdateApproval = (list) => {
    return {
       type: ActionTypes.CAMPAIGN_LIST_UPDATE_APPROVAL,
       payload: list,
    }
};
export const getCampaignListForAfterApprove = (list) => {
    return {
       type: ActionTypes.CAMPAIGN_LIST_AFTER_APPROVAL,
       payload: list,
    }
};
export const getMosaicRequestList = (mosaic) => {
    return {
       type: ActionTypes.MOSAIC_REQUEST_LIST,
       payload: mosaic,
    }
};
export const getWrapUpRequestList = (wrap) => {
    return {
       type: ActionTypes.WRAP_UP_REQUEST_LIST,
       payload: wrap,
    }
};
export const getWrapUpRequestListComplete = (wrap) => {
    return {
       type: ActionTypes.WRAP_UP_REQUEST_LIST_COMPLETE,
       payload: wrap,
    }
};
export const getPayoutsList = (wrap) => {
    return {
       type: ActionTypes.PAYOUTS_LIST,
       payload: wrap,
    }
};
