/* eslint-disable */
import React from "react";
import moment from "moment";
import ButtonComponent from "../Common/ButtonComponent";
import { getBadgeColor } from "../Shared/utils";
import { Collapse, Image } from "react-bootstrap";
import MoreIcon from "../../assets/img/more_options.png";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

const ViewItem = ({
  user,
  RequestUpdate,
  activeTableDetails,
  ElseMoreDetails,
  ViewMoreDetails,
}) => {
  return (
    <>
      <tr>
        <td style={{ width: "10px" }}>
          <button
            className={ activeTableDetails === user?.id? "btn actionbtn active": "btn actionbtn" }
            onClick={() =>activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
            aria-controls="example-collapse-text"
            aria-expanded={activeTableDetails === user?.id ? true : false} >
            <Image src={MoreIcon} alt="more icon" />
          </button>
        </td>
        <td>{user?.campaign?.title ? user?.campaign?.title : "-"}</td>
        <td>{user?.campaign?.campaign_goal ? `${Number(user?.campaign?.campaign_goal / 100)?.toFixed(2)}` : "-"} </td>
        <td>{user?.campaign?.total_contributions? `${Number(user?.campaign?.total_contributions / 100)?.toFixed(2)}` : "-"} </td>
          <td className="space-nowrap text-nowrap">
            <Form className="switchbtn">
              <Badge
                bg={getBadgeColor(user?.campaign?.status)}
                className={`text-capitalized`}>
                {user?.campaign?.status === "pending" ? "draft" : user?.campaign?.status}
              </Badge>
            </Form>
        </td>
        <td>
          <div className="d-flex gap-2">
            <ButtonComponent
              title={"Confirm"}
              color={"#34c759"}
              onClick={() => RequestUpdate(user)}
            />
          </div>
        </td>
      </tr>
      <Collapse in={activeTableDetails === user?.id ? true : false}>
        <tr className="tablecollapsewraps" id={"tablecollpase" + user?.id}>
          <td colSpan="8">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "20%" }}>Campaign Start Date </th>
                  <td>{user?.campaign?.start_date ? moment(user?.campaign?.start_date).format("LL") : "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Campaign End Date </th>
                  <td>{user?.campaign?.end_date ? moment(user?.campaign?.end_date).format("LL"): "-"}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </Collapse>
    </>
  );
};

export default ViewItem;
