import React from "react";
import { Form } from "react-bootstrap";

const FormInput = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  onKeyUp,
  onKeyDown,
  placeholder,
  error,
  required = false,
  maxLength,
  labelClassName="",
  divClassName="",
  inputClassName=""
}) => {
  return (
    <div className="row">
    {
        label &&(
      <div className={labelClassName}>
        <div className="formgroups">
          <h4 className={required ? "label-required font-size" : "font-size"}>{label}</h4>
        </div>
      </div>
        )
    }
      <div className={divClassName}>
        <Form.Group className={`${inputClassName} mb-3`}>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        <div style={{height:'15px'}}>
          {error && <div className="text-danger" style={{fontSize:'12px'}}>{error}</div>}
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export default FormInput;
