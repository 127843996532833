/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { getPayoutsList } from "../../redux/actions/campaignAction";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import { CAMPAIGN_PAYOUTS } from "../Shared/constant";
import { getBadgeColor } from "../Shared/utils";


const CampaignsPayouts = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { payouts_list } = useSelector((state) => state.campaignReducers);

  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});



  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  const fetchDataOfApprovalListing = async (
    userLimit,
    currentPage,
    userSearch,
    signal
  ) => {
    const response = await GetRequestCallAPI(
      `${CAMPAIGN_PAYOUTS}/${userLimit}/${currentPage}?search=${userSearch}&status=complete`,
      accessToken,
      signal && signal
    );
    if (response?.status === 200) {
      dispatch(getPayoutsList(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getPayoutsList({data: [],}));
    } else {
      dispatch(getPayoutsList({data: [],}));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit,currentPage,userSearch,controller.signal);
    return () => controller.abort();
  }, [userLimit, currentPage, userSearch]);

  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Payment method</th>
                  <th>Amount</th>
                  <th>Created At</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {payouts_list && payouts_list?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.campaign?.title ? user?.campaign?.title : "-"}</td>
                          <td> {user?.method ? user?.method : "-"} </td>
                          <td>{user?.amount ? user?.amount : "-"} </td>
                          <td>{user?.created_at ? moment(user?.created_at).format('LL HH:mm A') : "-"} </td>
                          <td className="space-nowrap text-nowrap">
                            <Form className="switchbtn">
                              <Badge
                                bg={getBadgeColor(user?.status)}
                                className={`text-capitalized`}>
                                {user?.status}
                              </Badge>
                            </Form>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {payouts_list && payouts_list?.data?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Campaign Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {parseInt(payouts_list?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};
export default CampaignsPayouts;
