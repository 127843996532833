/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import MoreIcon from '../../assets/img/more_options.png';
import ViewIcon from '../../assets/img/show.png';
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import GetCampaign from "../../Api/GetCampaign";
import { useSelector, useDispatch } from "react-redux";
import { getCampaignList, SelectCampaignTab, GetCampaignListIDWise } from "../../redux/actions/campaignAction";
import { useNavigate, Link } from "react-router-dom";
import CommonModal from "../Common/CommonModal";
import Avatar from "../Common/Avatar";
import CommonLogout from "../Common/CommonLogout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import CommonPagination from "../Common/CommonPagination";
import CampaignItem from "./CampaignItem";
import { CAMPAIGN_APPROVAL_LIST } from "../Shared/constant";
import { GetRequestCallAPI } from "../../Api/GetRequest";
const CampaignManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { campaignList } = useSelector((state) => state.campaignReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ added_by, setAddedBy ] = useState("all");
    const [ isStatus, setIsStatus ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        // fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        // fetchData(1,userLimit,e.target.value);
        setCurrentPage(1);
    };
    const [abortController, setAbortController] = useState({});

    // useEffect(() => {
    //     fetchData(1,userLimit,userType);
    //      return () => {
    //         if(abortController.abort){
    //             abortController.abort();
    //             setAbortController(new AbortController());
    //         }
    //     };
    // }, [ userSearch]);

    // Get data from api
    // const fetchData = async (currentPage,userLimit,userType) => {
    //     const signal = abortController.signal;
    //     setCurrentPage(currentPage)
    //     const responce = await GetCampaign(accessToken, userSearch, userType, userLimit, currentPage, {signal});
    //     
    //     if(responce?.status === 200) {
    //         dispatch(getCampaignList(responce.data));
    //         dispatch(GetCampaignListIDWise(responce.data));
    //         setPaginationLength(responce.pagination);
    //     } else if(responce?.status === 403) {
    //         CommonLogout();
    //         dispatch(GetCampaignListIDWise([]));
    //         dispatch(getCampaignList([]));
    //     } else {
    //         dispatch(GetCampaignListIDWise([]));
    //         dispatch(getCampaignList([]));
    //     };
    // };

      const fetchDataOfApprovalListing = async (userLimit, currentPage,signal,userSearch,added_by,isStatus,userType) => {
        // userType,
        // const response = await GetRequestCallAPI(
        //   `${CAMPAIGN_APPROVAL_LIST}/${userLimit}/${currentPage}?userSearch && search=${userSearch}&method=main`,
        //   accessToken,
        //   signal && signal 
        // );
        let url = `${CAMPAIGN_APPROVAL_LIST}/${userLimit}/${currentPage}?method=main`;

        if (userSearch) {
         url += `&search=${encodeURIComponent(userSearch)}`;
        }
        if(isStatus){
            url += `&status=${encodeURIComponent(isStatus)}`;
        }
        if(added_by){
            url += `&added_by=${encodeURIComponent(added_by)}`;
        }
        if(userType){
            url += `&is_active=${encodeURIComponent(userType==="all"?"all":userType==="active"?true:false)}`;
            // url += `&is_active=${userType === "active" ? true : userType === "inactive" ? false : "all"}`;

        }

    const response = await GetRequestCallAPI(url, accessToken, signal && signal);
        if (response?.status === 200) {
            dispatch(getCampaignList(response?.data?.data));
            setPaginationLength(response?.data?.pagination);
            dispatch(GetCampaignListIDWise(response?.data?.data));
        } else if (response?.status === 403) {
            CommonLogout();
            dispatch(GetCampaignListIDWise([]));
            dispatch(getCampaignList([]));
        } else {
            dispatch(GetCampaignListIDWise([]));
            dispatch(getCampaignList([]));
        }
      };


      useEffect(() => {
          const controller = new AbortController();
          
          fetchDataOfApprovalListing(userLimit, currentPage, controller.signal,userSearch,added_by,isStatus,userType);
        
          return () => controller.abort(); 
        
        }, [userLimit, currentPage, userSearch,added_by,isStatus,userType]);
    

    // Campaign active or deactive
    const CharityActivStatus = (Campaign) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${Campaign?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Campaign Active",
            Data: Campaign
        });
    };
    const CharityActivStatus2 = (Campaign) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${Campaign?.web_appearance ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Web Appearance Active",
            Data: Campaign
        });
    };

    // add Campaign
    const AddCampaign = () => {
        navigate("/add/campaign");
        dispatch(SelectCampaignTab("add-campaign"));
    };

    // Delete Campaign 
    const DeleteCampaign = (Campaign) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Campaign",
            category: "Delete Campaign",
            Data: Campaign
        });
    };
    const RestoreCampaign = (Campaign) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to restore",
            modalType: "not form",
            button: "Restore Campaign",
            category: "Restore Campaign",
            Data: Campaign
        });
    };
    const callUserLimit = (e) =>{
        setUserLimit(e);
        // fetchData(1, e,userType)
    }
    const [ activeTableDetails, setActiveTableDetails ] = useState("");

const ViewMoreDetails = (id) => {
    setActiveTableDetails(id);
};

// hide more user details
const ElseMoreDetails = () => {
    setActiveTableDetails("");
};

// console.log("campaignList",campaignList)
    return(<>
        <FiltersForm 
            filterType={"Campaign (Name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            AddCampaign={AddCampaign}
            added_by={true}
            setAddedBy={setAddedBy}
            addedBy={added_by}
            is_status={true}
            setIsStatus={setIsStatus}
            isStatus={isStatus}

        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Campaign Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Campaign By</th>
                                <th>Status</th>
                                <th>Web Appearance</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {campaignList && campaignList?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <CampaignItem 
                                activeTableDetails={activeTableDetails}
                                ViewMoreDetails={ViewMoreDetails}
                                ElseMoreDetails={ElseMoreDetails}
                                CharityActivStatus={CharityActivStatus} CharityActivStatus2={CharityActivStatus2} DeleteCampaign={DeleteCampaign} RestoreCampaign={RestoreCampaign} user={user} />
                            </React.Fragment>)
                            })}
                            {campaignList && campaignList?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Campaign Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}

            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} 
        callBack={() => fetchDataOfApprovalListing(userLimit, currentPage,null,userSearch,added_by,isStatus,userType)}
         />)}
    </>);
}

export default CampaignManagement;