export const LOGIN_URL = "/login";
export const DASHBOARD_URL = "/dashboard";
export const CHARITY_MANAGEMENT_URL = "/charity-management";
export const ATHLETE_MANAGEMENT_URL = "/athlete-management";
export const USER_MANAGEMENT_URL = "/user-management";
export const FUNDRSISERS_MANAGEMENT_URL = "/fundraisers-management";
export const CATEGORY_MANAGEMENT_URL = "/category-management";
export const DONATIONS_MANAGEMENT_URL = "/donations-management";
export const FUND_REQUEST_MANAGEMENT_URL = "/fund-request-management";
export const ADMIN_BLOG_MANAGEMENT_URL = "/admin-blog-management";
export const BLOG_CATEGORY_MANAGEMENT_URL = "/blog-category-management";
export const BANK_SECTION_URL = "/bank-section";
export const CONTACT_MANAGEMENT_URL = "/contact-list";
export const FUNDRAISE_APPLICATION_MANAGEMENT_URL = "/campaign-application-management";
export const CAMPAIGN_MANAGEMENT_URL = "/campaign-management";
export const MAIL_CAMPAIGN_MANAGEMENT = "/custom-email-management";
export const TEMPLATE_SUCCESS_POPUP = "/template-success-popup";
export const MOSAIC_MANAGEMENT = "/mosaic-management";
export const PACKAGE_MANAGEMENT = "/admin/package/add"
export const ORDER_LIST = "/admin/package-order"
export const MOSAIC_MANAGEMENT_URL="/mosaic-management"
export const PAYMENT_LIST="/admin/payment/list"
export const SETTING_MODULE = "/setting-module-management";
export const BLOG_MANAGEMENT = "/blog-management";
export const FREE_COUPON = "/coupon-free"
export const CAMPAIGN_APPROVAL_URL = "/campaign-approval";
export const CAMPAIGN_UPDATE_APPROVAL_URL = "/campaign-update-approval";

export const CAMPAIGN_UPDATE_REQUEST_URL = "/campaigns-update-request";
export const MOSAIC_REQUEST_URL = "/mosaic-requests";
export const MOSAIC_APPROVED_URL = "/mosaic-approved";
export const CAMPAIGN_WRAP_UP_URL = "/campaigns-warp-up";
export const CAMPAIGN_WRAP_UP_DONE_URL = "/campaigns-warp-up-request-done";
export const CAMPAIGN_PAYOUTS_URL = "/campaigns-payouts";
export const MOSAIC_EDIT = "/mosaic-approved/edit/mosaic-image/";


// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const API_URL_FOR_CREATING_COMPAIGN_URL = process.env.REACT_APP_BASE_URL_CREATING_COMPAIGN_URL;
export const LOGIN_API_URL = API_URL + "admin/login";

export const ADD_COUPON_API_URL = API_URL + "admin/add-coupon";
export const UPDATE_COUPON_API_URL = API_URL + "admin/update-coupon";
export const GET_COUPON_API_URL = API_URL + "admin/coupon/list";
export const DELETE_COUPON_API_URL = API_URL + "admin/delete-coupon";

// dashboard api
export const GET_DASHBOARD_API_URL = API_URL + "admin/counts";

// upload file urls
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";

// Api user list urls
export const GET_USER_LIST_API_URL = API_URL + "admin/users-list";
export const EDIT_USER_LIST_API_URL = API_URL + "admin/update-user";

// Api category list urls
export const ADD_CATEGORY_LIST_API_URL = API_URL + "admin/fundraise/add-category";
export const GET_CATEGORY_LIST_API_URL = API_URL + "admin/fundraise/category/list";
export const EDIT_CATEGORY_LIST_API_URL = API_URL + "admin/fundraise/update-category";
export const DELETE_CATEGORY_LIST_API_URL = API_URL + "admin/fundraise/delete-category";

// Api blog category list urls
export const ADD_BLOG_CATEGORY_LIST_API_URL = API_URL + "admin/blog/add-category";
export const GET_BLOG_CATEGORY_LIST_API_URL = API_URL + "admin/blog/category/list";
export const EDIT_BLOG_CATEGORY_LIST_API_URL = API_URL + "admin/blog/update-category";
export const DELETE_BLOG_CATEGORY_LIST_API_URL = API_URL + "admin/blog/delete-category";

// Api blog list urls
export const ADD_BLOG_LIST_API_URL = API_URL + "admin/blog/add";
export const GET_BLOG_LIST_API_URL = API_URL + "admin/blog/list";
export const EDIT_BLOG_LIST_API_URL = API_URL + "admin/blog/update";
export const GET_BLOG_DETAILS_API_URL = API_URL + "blog/get";
export const DELETE_BLOG_LIST_API_URL = API_URL + "admin/blog/delete";

// Api fundraise urls
export const ADD_FUNDRAISE_API_URL = API_URL + "admin/fundraise/add";
export const GET_FUNDRAISE_LIST_API_URL = API_URL + "admin/fundraise/adminlist";
export const EDIT_FUNDRAISE_API_URL = API_URL + "admin/fundraise/update";
export const GET_FUNDRAISE_DETAILS_API_URL = API_URL + "fundraise/get";
export const DELETE_FUNDRAISE_LIST_API_URL = API_URL + "admin/fundraise/delete";

// Api fundraise updates urls
export const ADD_FUNDRAISE_UPDATES_LIST_API_URL = API_URL + "admin/fundraise/updates/add";
export const GET_FUNDRAISE_UPDATES_LIST_API_URL = API_URL + "fundraise/updates";
export const EDIT_FUNDRAISE_UPDATES_LIST_API_URL = API_URL + "admin/fundraise/updates/update";
export const DELETE_FUNDRAISE_UPDATES_LIST_API_URL = API_URL + "admin/fundraise/updates/delete";
export const FUNDRAISE_WISE_DONATIONS_LIST_API_URL = API_URL + "admin/fundraise";

// Api get charity
export const GET_CHARITY_LIST_API_URL = API_URL + "admin/charity/list";
export const EDIT_CHARITY_LIST_API_URL = API_URL + "admin/charity/update";
export const ADD_CHARITY_LIST_API_URL = API_URL + "admin/charity/add";
export const DELETE_CHARITY_LIST_API_URL = API_URL + "admin/charity/delete";
export const GET_CHARITY_FUNDRAISE_API_URL = API_URL + "charity/fundraise/list/";
export const GET_CHARITY_DETAILS_API_URL = API_URL + "charity/get";

// Api get athlete
export const GET_ATHLETE_LIST_API_URL = API_URL + "admin/athlete/list";
export const EDIT_ATHLETE_LIST_API_URL = API_URL + "admin/athlete/update";
export const ADD_ATHLETE_LIST_API_URL = API_URL + "admin/athlete/add";
export const DELETE_ATHLETE_LIST_API_URL = API_URL + "admin/athlete/delete";
export const GET_ATHLETE_FUNDRAISE_API_URL = API_URL + "athlete/fundraise/list/";

// Api contact list
export const GET_CONTACT_US_LIST_API_URL = API_URL + "admin/contact-us/list";
export const EDIT_CONTACT_US_LIST_API_URL = API_URL + "admin/contact-us/update";
export const DELETE_CONTACT_US_LIST_API_URL = API_URL + "admin/contact-us/delete";

// Api Fundraise Application Form List
export const GET_FUNDRAISE_APPLICATION_API_URL = API_URL + "admin/fundraise/application/list";
export const EDIT_FUNDRAISE_APPLICATION_API_URL = API_URL + "admin/fundraise/application/update";
export const DELETE_FUNDRAISE_APPLICATION_API_URL = API_URL + "admin/fundraise/application/delete";

// Api donation list
export const GET_FUNDRAISE_DONATION_LIST_API_URL = API_URL + "admin/fundraise/donation/list";
// export const GET_DONATION_LIST_API_URL = API_URL + "admin/campaign/donation/list";
export const GET_DONATION_LIST_API_URL = API_URL + "admin/campaign/contribution-list";
// export const EDIT_DONATAION_API_URL = API_URL + "campaign/donation/update" previous api
export const EDIT_DONATAION_API_URL = API_URL + "payment/contribution/update"



// Api campaign list
export const GET_CAMPAIGN_LIST_API_URL = API_URL + "campaign/adminlist";
export const ADD_CAMPAIGN_API_URL = API_URL + "campaign/add";
export const EDIT_CAMPAIGN_API_URL = API_URL + "campaign/update";
export const GET_CAMPAIGN_DETAILS_API_URL = API_URL + "campaign/get";
export const DELETE_CAMPAIGN_LIST_API_URL = API_URL + "admin/campaign/delete";
export const PARENT_COMPAIGN_API_URL = API_URL + "campaign/detail"

// Api logout urls
export const LOGOUT_API_URL = API_URL + "user/logout";
// blogs
export const BLOG_LIST_API_URL = API_URL + "admin/blog/list";
export const BLOG_DELETE_API_URL = API_URL + "admin/blog/delete";
export const BLOG_UPDATE_API_URL = API_URL + "admin/blog/update";
export const BLOG_ADD_API_URL = API_URL + "admin/blog/add";
export const BLOG_GET_API_URL = API_URL + "blog/get";


// Api for Package URl 

export const ADD_PACKAGE_API_URL = API_URL + "admin/package/add"
export const GET_PACKAGE_LIST_API_URL = API_URL + "/admin/package/list"
export const DELETE_PACKAGE_LIST_API_URL = API_URL + "admin/package/delete"
export const EDIT_PACKAGE_API_URL = API_URL + "admin/package/update"


// Api for order
export const GET_ORDER_LIST_DATA =  API_URL + "admin/package-order/list"
export const GET_ORDER_DETAILS = API_URL + "admin/package-coupons/list"
export const GET_PAYMENT_LIST = API_URL + "admin/payment/list"

// EMail template
export const ADD_CAMPAIGN_MAIL = API_URL + "admin/campaign-mail/add";
export const UPDATE_CAMPAIGN_MAIL = API_URL + "admin/campaign-mail/update";
export const CAMPAIGN_MAIL_LIST = API_URL + "admin/campaign-mail/list";
export const GET_CAMPAIGN_MAIL = API_URL + "admin/campaign-mail/get";

export const VALIDATE_MOSAIC_SOURCE_IMAGE = API_URL + "admin/mosaic/validate/source-image"
export const LIST_MOSAIC_SOURCE_IMAGE = API_URL + "admin/mosaic/list"
export const CREATE_MOSAIC_SOURCE_IMAGE = API_URL + "admin/mosaic/create"

export const LIST_SETTING_MODULE = API_URL + "admin/settings-data/get"
export const UPDATE_SETTING_MODULE = API_URL + "admin/settings-data/update"

export const CAMPAIGN_APPROVAL_LIST = API_URL + "admin/campaign/list"
export const CAMPAIGN_APPROVAL_ACTION = API_URL + "admin/campaign/action"
export const CAMPAIGN_UPDATE_REQUEST_LIST = API_URL + "admin/campaign/update-request-list"
export const CAMPAIGN_UPDATE_PARTICULAR_REQUEST = API_URL + "admin/campaign/update-request"
export const ADMIN_ADD_CAMPAIGN_API_URL = API_URL + "admin/campaign/add"
export const ADMIN_CAMPAIGN_RESTORE_API_URL = API_URL + "admin/campaign/restore"
export const ADMIN_CAMPAIGN_CONTRIBUTION_LIST = API_URL + "admin/campaign/contribution-list"


export const MOSAIC_REQUEST_LIST_API = API_URL +'campaign/mosaic-request/list'
export const MOSAIC_REQUEST_ACTION = API_URL +'campaign/mosaic-request/update'  //  //admin/mosaic-request/update
export const WRAPPING_REQUEST_LIST_API = API_URL +'campaign/wrap-request/list'
export const CAMPAIGN_PAYOUTS = API_URL +'admin/user-payouts'
export const WRAPPING_REQUEST_ACTION = API_URL +'admin/campaign/wrap-update'
export const MOSAIC_UPDATE_API = API_URL +'campaign/mosaic-request/update'




// export const Img_url = {
//     Date: `${window.location.origin}../../assets/img/Calendar.svg`,
    
// };