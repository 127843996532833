import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PostRequestAPI } from '../../Api/PostRequest';
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import { MOSAIC_APPROVED_URL, MOSAIC_UPDATE_API } from '../Shared/constant';

import UploadFile from '../../Api/UploadFile';
 const MosaicEdit = () => {
      const { accessToken } = useSelector((state) => state.personalReducers);
      const param = useParams()
      const navigate = useNavigate();
      const [formData, setFormData] = useState({
            file:null,
        });
      const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
      const [load,setLoad] = useState(false);
      const [error, setError] = useState({file:[]});

      const validation = () =>{
            let val = true;            
            if(!formData?.file){
                error.file = "Please attach file";
                val = false;
            }    
            setError((data)=>({
                ...data,
                ...error,
            }))
            return val;
        }

      const UploadImage = async (e, url) => {
                const files = e.target.files[0];        
                const maxFileSize = 20 * 1024 * 1024; 
                if (files.size > maxFileSize) {
                    toast.error("File size should not exceed 20MB"); 
                    return;  
                }
                const validFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
        
                if (!validFormats.includes(files.type)) { 
                    toast.error("Invalid file type. Please select an image file (jpg, jpeg, png, webp)."); 
                    return;  
                }
                if(files){
                    setError((data)=>({
                        ...data,
                        file: "",
                    }))
                }
                const formData1 = new FormData();
                formData1.append("file", files, files.name);
                setUploadFileLoader(true);
                const fileresponse = await UploadFile(accessToken, formData1);
                if(fileresponse) {
                    setFormData((data)=>({
                        ...data,
                        file: fileresponse,
                        file_preview: URL.createObjectURL(files),
                    }))
                };
                e.target.value = null;
                setUploadFileLoader(false);
      }
      const callRemoveSponsorsLogo = (e) =>{
                setFormData((data)=>({
                    ...data,
                    file:null,
                    file_preview:null
                }))
      }

     const onSubmit = async ( e ) =>{
     if(validation()){
                    setLoad(true)
                    const payload = {
                        request_id:param?.id,
                        mosaic_image:{
                            // id:formData?.file?.id,
                            view_file_url:formData?.file?.view_file_url,
                            file_url_400:formData?.file?.file_url_400,
                            file_url_700:formData?.file?.file_url_700,
                        }
                    };
                    const response = await PostRequestAPI(MOSAIC_UPDATE_API,payload,accessToken,false);
                    if (response?.status === 200) {
                        toast.success(response?.data?.msg);
                        setLoad(false)
                        navigate(MOSAIC_APPROVED_URL, { replace: true })
                    } else {
                        toast.error(response?.data?.error);
                        setLoad(false)
                    }
        
     }
     }

  return (
<>
<ul className="tabcomnsection">
            <li className={ "active text-capitalized"} >
                Upload mosaic Image
            </li>
            <div className="tabheadebuttns">
                <Button className="savchanges" onClick={onSubmit} disabled={load} >Save</Button>
                <Link className="btn savchanges linksmanage" to={MOSAIC_APPROVED_URL} >Back</Link>
            </div>
        </ul>
                            <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Upload Mosaic Image</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="" >
                                            {!formData?.file && (
                                                <div className="uploadbuttons me-2 mb-2"
                                                 onClick={() => document.getElementById('fileInput')?.click()}
                                                >
                                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                        {UploadFileLoader ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                            )}
                                            {formData?.file_preview && (
                                                <React.Fragment>
                                                    <div className="imageuploads bg-grey me-2 mb-2">
                                                        <Image src={formData?.file_preview} alt="charity avatar" />
                                                        <span className="closeIcon" onClick={() => callRemoveSponsorsLogo()}></span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        {error?.file && <div className="danger-colorset">{error?.file}</div>}
                                    </div>
                                </div> 
</>
  )
}
export default MosaicEdit