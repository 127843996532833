import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  label,
  name,
  value,
  onChange,
  onKeyUp,
  onKeyDown,
  errorMessage,
  placeholder
}) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="formgroups">
          <h4 className="label-required font-size">{label}</h4>
        </div>
      </div>
      <div className="col-md-8">
        <div className="editorBox">
          <ReactQuill name={name} placeholder={placeholder} value={value} onChange={(content) => onChange(name, content)} onKeyDown={onKeyDown} />
          {errorMessage && <div className="danger-colorset">{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
