/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { getWrapUpRequestList } from "../../redux/actions/campaignAction";
import CommonLogout from "../Common/CommonLogout";
import CommonModal from "../Common/CommonModal";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import {
  WRAPPING_REQUEST_LIST_API
} from "../Shared/constant";
import ViewItem from "./ViewItem";

const CampaignsWrapUp = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { wrapUpRequestList } = useSelector((state) => state.campaignReducers);

  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});
  const [loading, setLoading] = useState({ id: "", status: "" });
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
      const [ activeTableDetails, setActiveTableDetails ] = useState("");
      const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };
    
    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  const fetchDataOfApprovalListing = async (userLimit,currentPage,userSearch,signal) => {
    const response = await GetRequestCallAPI(`${WRAPPING_REQUEST_LIST_API}/${userLimit}/${currentPage}?search=${userSearch}&status=requested`,accessToken,signal && signal);
    if (response?.status === 200) {
      dispatch(getWrapUpRequestList(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getWrapUpRequestList({data:[]}));
    } else {
      dispatch(getWrapUpRequestList({data:[]}));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit,currentPage,userSearch,controller.signal);
    return () => controller.abort();
  }, [userLimit, currentPage, userSearch]);

  const RequestUpdate = (data) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      description: ``,
      modalType: "not form",
      button: "Yes",
      category: "Wrap Request Update",
      Data: data,
    });
  };
  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Campaign Name</th>
                  <th>Campaign Goal</th>
                  <th>Total Contributions</th>
                  <th>Campaign Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {wrapUpRequestList && wrapUpRequestList?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ViewItem
                          user={user}
                          loading={loading}
                          RequestUpdate={RequestUpdate}
                          activeTableDetails={activeTableDetails}
                          ViewMoreDetails={ViewMoreDetails}
                          ElseMoreDetails={ElseMoreDetails}
                        />
                      </React.Fragment>
                    );
                  })}
                {wrapUpRequestList && wrapUpRequestList?.data?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Campaign Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {parseInt(wrapUpRequestList?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModal
          showModal={showModal}
          setShowModal={setShowModal}
          isWrap={true}
          callBack={() => fetchDataOfApprovalListing(userLimit, currentPage)}
        />
      )}
    </>
  );
};
export default CampaignsWrapUp;
