// import moment from "moment";
// import { GET_DONATION_LIST_API_URL} from "../components/Shared/constant";
// const GetFundDonations = async (accessToken, userSearch, userType, userLimit, currentPage, { signal } = {}, param, paymentMethod) => {
//   const payload = {
//     start_date:"0",
//     end_date:"0",
//     res:"json",
//     campaign:"all"
//   }
//   if(param?.start_date){
//     payload.start_date = moment(param?.start_date).format("YYYY-MM-DD HH:mm:ssZ")
//   }
//   if(param?.end_date){
//     payload.end_date = moment(param?.end_date).format("YYYY-MM-DD HH:mm:ssZ")
//   }

//   const responce = window.axios.get(`${GET_DONATION_LIST_API_URL}/${userType}/${payload.campaign}/${paymentMethod}/${payload?.start_date}/${payload?.end_date}/${userLimit}/${currentPage}/${userSearch === "" ? "0" : userSearch}/${payload.res}`, {
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Authorization': 'Bearer ' + accessToken,
//     },
//     signal:signal,
//   }).then(function (result) {
//         return {
//           ...result.data,
//           status:200
//         };
//     }).catch(function (result) {
//         return {
//           ...result?.response?.data,
//           status:result?.response?.status
//         }
//     });
//     return responce;
// };
  
// export default GetFundDonations;

import moment from "moment";
import { ADMIN_CAMPAIGN_CONTRIBUTION_LIST } from "../components/Shared/constant";

const GetFundDonations = async (
  accessToken,
  userSearch,
  userType,
  userLimit,
  currentPage,
  { signal } = {},
  param,
  paymentMethod
) => {
  const payload = {
    start_date: param?.start_date
      ? moment(param.start_date).format("YYYY-MM-DD HH:mm:ssZ")
      : "0",
    end_date: param?.end_date
      ? moment(param.end_date).format("YYYY-MM-DD HH:mm:ssZ")
      : "0",
    res: "json",
    // campaign: "all",
  };

  let url = `${ADMIN_CAMPAIGN_CONTRIBUTION_LIST}/${userLimit}/${currentPage}`;
  
  let params = new URLSearchParams();
  
  if (userType) params.append("filter", userType);
  // if (payload.campaign) params.append("campaign", payload.campaign);
  if (paymentMethod) params.append("method", paymentMethod);
  if (payload.start_date !== "0") params.append("start_date", payload.start_date);
  if (payload.end_date !== "0") params.append("end_date", payload.end_date);
  if (payload.end_date !== "0") params.append("end_date", payload.end_date);
  if (userSearch) params.append("search", userSearch);
  // params.append("res", payload.res);
  // if (param?.export) params.append("export", param.export);

  url += `?${params.toString()}`;

  try {
    const result = await window.axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      signal: signal,
    });
    return { ...result.data, status: 200 };
  } catch (error) {
    return { ...error?.response?.data, status: error?.response?.status };
  }
};

export default GetFundDonations;
