/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { getCampaignListForApproval } from "../../redux/actions/campaignAction";
import ButtonComponent from "../Common/ButtonComponent";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import { CAMPAIGN_APPROVAL_ACTION, CAMPAIGN_APPROVAL_LIST } from "../Shared/constant";


const CampaignApproval = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { campaignListForApproval } = useSelector(
    (state) => state.campaignReducers
  );
  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});
  const [loading, setLoading] = useState({id: "",status:""});
  

  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };



  const fetchDataOfApprovalListing = async (userLimit, currentPage,signal) => {
    const response = await GetRequestCallAPI(`${CAMPAIGN_APPROVAL_LIST}/${userLimit}/${currentPage}?search=${userSearch}&method=approval`,accessToken,signal && signal);
    if (response?.status === 200) {
      dispatch(getCampaignListForApproval(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getCampaignListForApproval({data:[]}));
    } else {
      dispatch(getCampaignListForApproval({data:[]}));
    }
  };



useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit, currentPage, controller.signal);  
    return () => controller.abort(); 
  }, [userLimit, currentPage, userSearch]);


  const RequestUpdate  =async (id,status) =>{
    setLoading({id:id,status:status})
    const response = await GetRequestCallAPI(`${CAMPAIGN_APPROVAL_ACTION}/${id}/${status}`,accessToken)
    if (response?.status === 200) {
        toast.success(response?.data?.msg);
        setLoading({id: "",status: "",});
        fetchDataOfApprovalListing(userLimit, currentPage,userSearch);
    } else if (response?.status === 403) {
        CommonLogout();
        setLoading({id: "",status: "",});
      } else {
        toast.error(response?.data?.error);
        setLoading({id: "",status: "",});
      }
  }
  
  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Request At</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaignListForApproval && campaignListForApproval?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                      <tr>
                       <td>{user?.title?  user?.title :"-"}</td>
                       <td>{user.created_at?  moment(user.created_at).format("LL HH:mm A"):"-"}</td>
                       <td>{user.start_date?  moment(user.start_date).format("LL"):"-"}</td>
                       <td>{user.end_date?  moment(user.end_date).format("LL"):"-"}</td>
                      <td>
                      <div className="d-flex gap-2">
                       <ButtonComponent title={"Approve"} color={"#34c759"} isLoading={(loading?.id === user?.id&&loading?.status==="approved") ?  true: false} onClick={()=>RequestUpdate(user?.id,"approved")} />
                       <ButtonComponent title={"Reject"} color={"#ff3b30"} isLoading={(loading?.id === user?.id &&loading?.status==="rejected" ) ?  true: false} onClick={()=>RequestUpdate(user?.id,"rejected")}/>            
                       </div>
                       </td>
                     </tr>
                      </React.Fragment>
                    );
                  })}
                {campaignListForApproval &&
                  campaignListForApproval?.data?.length === 0 && (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        Campaign Not Found !
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(campaignListForApproval?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};

export default CampaignApproval;
