/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import EditUserList from "../../Api/EditUserList";
import EditCategory from "../../Api/EditCategory";
import AddCategory from "../../Api/AddCategory";
import DeleteCategory from "../../Api/DeleteCategory";
import EditCharityList from "../../Api/EditCharityList";
import DeleteCharity from "../../Api/DeleteCharity";
import DeleteFundraise from "../../Api/DeleteFundraise";
import DeleteFundraiseApplication from "../../Api/DeleteFundraiseApplication";
import EditFundraise from "../../Api/EditFundraise";
import EditBlogCategory from "../../Api/EditBlogCategory";
import DeleteBlogCategory from "../../Api/DeleteBlogCategory";
import AddBlogCategory from "../../Api/AddBlogCategory";
import EditBlogAPI from "../../Api/EditBlogAPI";
import DeleteBlog from "../../Api/DeleteBlog";
import EditContactList from "../../Api/EditContactList";
import DeleteFundraiseUpdatesAPI from "../../Api/DeleteFundraiseUpdatesAPI";
import DeleteContactList from "../../Api/DeleteContactList";
import EditFundraiseApplications from "../../Api/EditFundraiseApplications";
import DeleteAthlete from "../../Api/DeleteAthlete";
import EditAthleteList from "../../Api/EditAthleteList";
import DeleteCampaign from "../../Api/DeleteCampaign";
import EditCampaign from "../../Api/EditCampaign";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editUserLists } from "../../redux/actions/usersActions";
import { editCategoryLists, addCategoryList, deleteCategoryLists } from "../../redux/actions/categoryActions";
import { editCharityListFnct, deleteCharityFnct } from "../../redux/actions/charityActions";
import { deleteFundraiseList, editFundraiseList, deleteFundraiseUpdatesList } from "../../redux/actions/fundActions";
import { editBlogCategoryLists, deleteBlogCategoryLists, addBlogCategoryList } from "../../redux/actions/blogCategoryActions";
import { editBlogLists, deleteBlogLists } from "../../redux/actions/blogActions";
import { editContactListAction, deleteContactListAction } from "../../redux/actions/contactusActions";
import { editFundraiseApplication, deleteFundraiseApplicationRdx } from "../../redux/actions/fundraiseApllicationActions";
import { deleteAthleteFnct, editAthleteListFnct } from "../../redux/actions/athleteAction";
import { DeleteCampaignList, UpdateCampaignX, setStoreUpdateBlogDetails } from "../../redux/actions/campaignAction";
import { json, useNavigate } from "react-router-dom";
import { ADMIN_CAMPAIGN_RESTORE_API_URL,API_URL_FOR_CREATING_COMPAIGN_URL,BLOG_DELETE_API_URL, BLOG_UPDATE_API_URL, DELETE_COUPON_API_URL, UPDATE_COUPON_API_URL, UPDATE_SETTING_MODULE, USER_MANAGEMENT_URL } from "../Shared/constant";
import CheckValid from "./CheckValid";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CommonLogout from "./CommonLogout";
import DeletePAckage from "../../Api/DeletePackage";
import { DeletePackageList, setDeleteCouponList, setUpdateCouponList, UpdatePackageX } from "../../redux/actions/packageAction";
import EditPackage from "../../Api/Editpackage";
import { PostRequestAPI } from "../../Api/PostRequest";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { AuthenticateResponse } from "../Shared/utils";
import { UpdateDonationList } from "../../redux/actions/donationAction";
import { EditDonation } from "../../Api/EditDonation";
import { WRAPPING_REQUEST_ACTION } from "../Shared/constant";

const CommonModal = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { showModal, setShowModal,callBack,isWrap } = props;
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { sponsorsData } = useSelector((state) => state.campaignReducers);
    const [ categoryName, setCategoryName ] = useState(showModal?.Data?.name === null ? "" : showModal?.Data?.name);
    const [ amount, setAmount ] = useState();
    const [ msg, setMsg ] = useState();
    // error field 
    const [ errorCategoryname, setErrorCategoryname ] = useState("");
    const [ errorAmount, setErrorAmount ] = useState("");
    const [ msgerror, setMessageError ] = useState("");

    // close modal
    const handleClose = () => {
        setShowModal({...showModal, open: false, Data: {}});
        setCategoryName("");
    };

    // edit user function
    const EditData = async () => {
        if(showModal?.category === "User Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                user_id: showModal?.Data.id
            });
            const response = await EditUserList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editUserLists(response.data));
                    handleClose();
                    Navigate(USER_MANAGEMENT_URL);
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } if(showModal?.category === "Blog update") {
            const jsonData = {
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                blog_id: showModal?.Data.id,
            }
            const response = await PostRequestAPI(BLOG_UPDATE_API_URL, jsonData, accessToken);
            console.log("response", response)
            if(response?.status === 200){
                    toast.success(response.msg);
                    // dispatch(setStoreUpdateBlogDetails(response.data?.data));
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                    handleClose();
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        }  if(showModal?.category === "Charity Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data.slug
            });
            const response = await EditCharityList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editCharityListFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        }  if(showModal?.category === "Campaign Active") {
            const payload = {
                is_active: showModal?.Data?.is_active === false ? "1" : "0",
                campaign_id: showModal?.Data?.id,
            }

            const response = await EditCampaign(accessToken, payload);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response?.msg);
                    // dispatch(UpdateCampaignX(response?.data));
                    if(callBack){
                       callBack()
                    }
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Add category") {
            if(categoryName !== "" && categoryName !== undefined) {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                });
                const response = await AddCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addCategoryList(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }      
        } else if(showModal?.category === "Add Blog Category") {
            if(categoryName !== "" && categoryName !== undefined) {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                });
                const response = await AddBlogCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addBlogCategoryList(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Edit category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                    slug: showModal?.Data?.slug
                });
                const response = await EditCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editCategoryLists(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Edit Blog Category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                    slug: showModal?.Data?.slug
                });
                const response = await EditBlogCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editBlogCategoryLists(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Athlete Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditCharityList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editAthleteListFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Category active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditCategory(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraiseApplications(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseApplication(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application verified") {
            const jsonData = JSON.stringify({
                is_verified: showModal?.Data.is_verified === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraiseApplications(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseApplication(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Blog Category active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditBlogCategory(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editBlogCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser comment") {
            const jsonData = JSON.stringify({
                is_comments: showModal?.Data.is_comments === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Blog Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditBlogAPI(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editBlogLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Contact Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? '1' : '0' , 
                contact: showModal?.Data?.slug
            });
            const response = await EditContactList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Contacted Us") {
            const jsonData = JSON.stringify({
                is_contacted: showModal?.Data.is_contacted === false ? '1' : '0' , 
                contact: showModal?.Data?.slug
            });
            const response = await EditContactList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser donations") {
            const jsonData = JSON.stringify({
                accept_donations: showModal?.Data.accept_donations === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete contact") {
            const response = await DeleteContactList(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete category") {
            const response = await DeleteCategory(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application delete") {
            const response = await DeleteFundraiseApplication(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseApplicationRdx(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog category") {
            const response = await DeleteBlogCategory(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteBlogCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Charity") {
            const response = await DeleteCharity(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteCharityFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Athlete") {
            const response = await DeleteCharity(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteAthleteFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Fundraise") {
            const response = await DeleteFundraise(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog") {
            const response = await DeleteBlog(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteBlogLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Fundraise Updates") {
            const response = await DeleteFundraiseUpdatesAPI(accessToken, showModal?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseUpdatesList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog Data") {
            //     const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            const response = await GetRequestCallAPI(`${BLOG_DELETE_API_URL}/${showModal?.Data?.slug}`, accessToken);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success("Blog deleted successfully");
                    handleClose();
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Sponsors") {
            console.log("showModal?.Data", showModal?.Data)
            const oldSponsors = sponsorsData?.facefunding_sponsors?.filter(item1=>item1?.id !== showModal?.Data?.id)
            //     const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success("Sponsor deleted successfully");
                    // dispatch(DeleteCampaignList(response.data));
                    handleClose();
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Campaign") {
            const response = await DeleteCampaign(accessToken, showModal?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(DeleteCampaignList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Package") {
            const response = await DeletePAckage(accessToken, showModal?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(DeletePackageList(response.data));
                    handleClose();
                }, 100);
            }else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Coupon") {
            const response = await GetRequestCallAPI(`${DELETE_COUPON_API_URL}/${showModal?.Data?.id}`, accessToken);
            console.log("response", response)
            if(response?.status === 200){
                toast.success(response?.data?.msg);
                dispatch(setDeleteCouponList(response?.data?.data));
                handleClose();
            }else {
                AuthenticateResponse(response);
            };
        } else if(showModal?.category ==="Coupon Active"){
            const jsonData = {
               coupon_id: showModal?.Data?.id,
               name: showModal?.Data?.name,
               quantity: showModal?.Data?.quantity,
               code: showModal?.Data?.code,
               is_active: showModal?.Data?.is_active === false ? 1 : 0,
             }
             const response = await PostRequestAPI(`${UPDATE_COUPON_API_URL}`,  jsonData, accessToken);
             console.log("response", response)
             if(response?.status === 200){
                toast.success(response?.data?.msg);
                dispatch(setUpdateCouponList(response?.data?.data));
                handleClose();
            }else {
                AuthenticateResponse(response);
            };
            
        } else if(showModal?.category ==="Package Active"){
            const jsonData = JSON.stringify({
               name:showModal?.Data?.name,
               quantity:showModal?.Data?.quantity,
               is_active: showModal?.Data?.is_active === false ? 1 : 0,
               package_id:showModal?.Data?.id
             })
             const response = await EditPackage(accessToken, jsonData);
             if(response?.status === 200){
                 setTimeout(() => {
                     toast.success(response.msg);
                     dispatch(UpdatePackageX(response.data));
                     handleClose();
                 }, 100);
             } else if(response?.detail === "Token expired.") {
                 CommonLogout();
                 toast.error(response?.error || response.data.error);
             } else {
                 toast.error(response?.error || response.data.error);
             };
        }else if(showModal?.category ==="Donation Active"){
            const jsonData = JSON.stringify({
               is_active: showModal?.Data?.is_active === false ? "1" : "0",
               id:showModal?.Data?.id
             })
             const response = await EditDonation(accessToken, jsonData);
             if(response?.status === 200){
                 setTimeout(() => {
                     toast.success(response.msg);
                     dispatch(UpdateDonationList(response.data));
                     handleClose();
                 }, 100);
             } else if(response?.detail === "Token expired.") {
                 CommonLogout();
                 toast.error(response?.error || response.data.error);
             } else {
                 toast.error(response?.error || response.data.error);
             };
        }else if(showModal?.category ==="Web Appearance Active"){
            const payload = {
                web_appearance: showModal?.Data?.web_appearance === false ? "1" : "0",
                campaign_id: showModal?.Data?.id,
            }

            const response = await EditCampaign(accessToken, payload);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    // dispatch(UpdateCampaignX(response.data));
                    if(callBack){
                        callBack()
                     }
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            }
        }else if(showModal?.category === "Restore Campaign") {
                const response = await GetRequestCallAPI(`${ADMIN_CAMPAIGN_RESTORE_API_URL}/${showModal?.Data?.id}`,accessToken)
                if (response?.status === 200) {
                setTimeout(() => {
                    console.log("response?.status",response)
                    toast.success(response?.data?.msg);
                    if(callBack){
                        callBack()
                     }
                     handleClose();
                }, 100);
               } else if (response?.status === 403) {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                  } else {
                      toast.error(response?.data?.error);
                      toast.error(response?.error || response.data.error);
                  }
    };
}

const onChange = (e) =>{
    const inputValue = e.target.value;
    if (e.target.type === "text") {
        const isNumeric = /^\d*\.?\d{0,2}$/.test(inputValue);
        if (!isNumeric && inputValue) {
            e.target.value = amount;
        }
        if (parseFloat(inputValue) <= 0) {
            e.target.value = "";
        }
        if (parseFloat(inputValue) > 999999) {
            e.target.value = amount;
        }
    }
    setAmount(e.target.value);
    setErrorAmount("");

}
    const UpdateWrapRequest = async() =>{
     if(amount!=="" &&amount!==null && amount !==undefined && msg!==""){
        const payload= {
            request_id : showModal?.Data?.id,
            status: "complete",
            amount: Number(amount) ,       
            message: msg        
        }
    const response = await PostRequestAPI(WRAPPING_REQUEST_ACTION, payload, accessToken)
         if(response?.status == 200){
         toast.success(response?.data?.msg);
         handleClose();
         
         }else if(response?.detail === "Token expired.") {
            CommonLogout();
            toast.error(response?.error || response.data.error);
        } else {
            toast.error(response?.error || response.data.error);
        }

     }else{
        if(amount==="" ||amount===null ||amount ===undefined){
            setErrorAmount("Please Enter Amount");
        }else{
            setErrorAmount("")
        }
        if(msg==="" ||msg===null ||msg ===undefined){
            setMessageError("Please enter msg")
        }else{
            setMessageError("")
        }
     }
    }

    return(<Modal className="commonModal" show={showModal?.open} onHide={handleClose}>
       {!isWrap ? 
        <>
        <Modal.Header closeButton>
          {/* <Modal.Title style={{ width: showModal?.title === "Are you sure?" ? "100%" : ""}}>{showModal?.title}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
            {showModal?.modalType === "form" ? (<div className="formloginset">
                <Form.Group className="mb-3" >
                    <Form.Label>Enter Category Name</Form.Label>
                    <Form.Control type="text" value={categoryName} onKeyUp={(e) => CheckValid(e.target.value, {type: 'categoryname', error: setErrorCategoryname })} onChange={(e) => setCategoryName(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter First Name" />
                    {errorCategoryname && <div className="danger-colorset">{errorCategoryname}</div>}
                </Form.Group>
            </div>) : (<>
                <div className="notformsection">
                    <p>{showModal?.description}</p>
                    {showModal?.category === "Delete Charity" ? (<h5>{showModal?.Data?.name}</h5>) : 
                    (showModal?.category === "Delete Fundraise" || showModal?.category === "Delete Campaign" || showModal?.category === "Delete Blog Data" || showModal?.category === "Delete Sponsors" || showModal?.category === "Delete Blog" || showModal?.category === "fundraiser active" || showModal?.category === "fundraiser comment" || showModal?.category === "fundraiser donations" || showModal?.category === "Blog Active" || showModal?.category === "Blog update") ? 
                    (<h5>{showModal?.Data?.title}</h5>) : (showModal?.category === "Contact Active" || showModal?.category === "Contacted Us") ? (<h5>{showModal?.Data?.from_email}</h5>) : 
                    (showModal?.category === "Campaign Active"|| "Web Appearance Active") ? (<h5>{showModal?.Data?.title}</h5>) :(<h5>{showModal?.Data?.email === undefined ? showModal?.Data?.name : showModal?.Data?.email}</h5>)}
                </div>
            </>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => EditData()}>
            {showModal?.button}
          </Button>
        </Modal.Footer>
        </>:
       <>
       <Modal.Header closeButton>
          {/* <Modal.Title style={{ width: showModal?.title === "Are you sure?" ? "100%" : ""}}>{showModal?.title}</Modal.Title> */}
        </Modal.Header>
       <Modal.Body>
            <div className="formloginset">
                <Form.Group className="mb-3" >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="text" value={amount} 
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'amount', error: setErrorAmount })}
                     onChange={onChange} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter amount" />
                    {errorAmount && <div className="danger-colorset">{errorAmount}</div>}
                </Form.Group>
            </div>
            <div className="formloginset">
                <Form.Group className="mb-3" >
                    <Form.Label>Message</Form.Label>
                    <Form.Control type="text" value={msg} 
                     onChange={(e)=>{setMsg(e.target?.value),setMessageError('')}} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter msg" />
                    {msgerror && <div className="danger-colorset">{msgerror}</div>}
                </Form.Group>
            </div>
        </Modal.Body>
        <Modal.Footer style={{justifyContent:"end"}}>
          <Button variant="primary" onClick={() => UpdateWrapRequest()}>
            Submit
          </Button>
        </Modal.Footer>
       </>
       }
    </Modal>);
}

export default CommonModal;