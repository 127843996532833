/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import MoreIcon from '../../assets/img/more_options.png';
import ViewIcon from '../../assets/img/show.png';
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CommonModal from "../Common/CommonModal";
import Avatar from "../Common/Avatar";
import CommonLogout from "../Common/CommonLogout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import CommonPagination from "../Common/CommonPagination";
import CampaignItem from "../CampaignManagement/CampaignItem";
import PackageItem from "./PackageItem";
import GetPackage from "../../Api/Getpackage";
import { SelectPackagesTab, getPackagesList, setStoreFreeCouponList } from "../../redux/actions/packageAction";
import { FREE_COUPON, GET_COUPON_API_URL } from "../Shared/constant";
import { GetRequestCallAPI } from "../../Api/GetRequest";
// import CampaignItem from "./CampaignItem";


const FreePackageContent = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { campaignList } = useSelector((state) => state.campaignReducers);
    const {packageList, freeCouponList} = useSelector((state)=>state.packageReducers)
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});
    const [FilterForm, setFilterForm] = useState({
        campaign:"",
    })


    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        fetchData(1,userLimit,e.target.value);
        setCurrentPage(1);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch,FilterForm?.campaign]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        console.log("FilterForm?.campaign ? FilterForm?.campaign: userSearch.trim()", FilterForm, userSearch.trim())
        const response = await GetRequestCallAPI(`${GET_COUPON_API_URL}/${userType}/${userLimit}/${currentPage}/${FilterForm?.campaign ? FilterForm?.campaign: userSearch.trim()||"0"}`, accessToken);
        if(response?.status === 200){
            dispatch(setStoreFreeCouponList(response?.data));
            setPaginationLength(response?.data?.pagination);
        }else{
            dispatch(setStoreFreeCouponList());   
        }
    };

    // Campaign active or deactive
    const CharityActivStatus = (Campaign) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${Campaign?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Coupon Active",
            Data: Campaign
        });
    };

    // add Campaign
    const AddPackage = () => {
        navigate(`/add${FREE_COUPON}`);
        dispatch(SelectPackagesTab("add-package"));
    };

    // Delete Campaign 
    const Deletepackage = (data) => {
        // console.log("hi")
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            modalType: "not form",
            button: "Delete Coupon",
            category: "Delete Coupon",
            Data: data
        });

    };
    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(
        <>
        <FiltersForm
            filterType={"Coupon Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            AddPackage={AddPackage}

            setFilterForm={setFilterForm}
            FilterForm={FilterForm}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 127px)" : "calc(100% - 90px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Coupon Name</th>
                                <th>Quantity</th>
                                <th>Coupon Code</th>
                                <th>Created At</th>
                                <th>Active</th>
                                <th>Action</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {freeCouponList?.data && freeCouponList?.data?.map((element, index) => {
                                return(
                                    <React.Fragment key={index}>
                                        <PackageItem hidePrice={true} CharityActivStatus={CharityActivStatus} Deletepackage={Deletepackage} element={element} />
                                    </React.Fragment>
                                )
                            })}
                            {freeCouponList?.data && freeCouponList?.data.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Coupon Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}

        </>
    )
}
export default FreePackageContent