/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { PostRequestAPI } from "../../Api/PostRequest";
import { getMosaicRequestList } from "../../redux/actions/campaignAction";
import ButtonComponent from "../Common/ButtonComponent";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import {
  MOSAIC_REQUEST_ACTION,
  MOSAIC_REQUEST_LIST_API,
} from "../Shared/constant";
import { getStatusColor,getBadgeColor } from "../Shared/utils";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

const MosaicRequest = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { mosaicRequestList } = useSelector((state) => state.campaignReducers);

  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});
  const [loading, setLoading] = useState({ id: "", status: "" });

  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  const fetchDataOfApprovalListing = async (userLimit, currentPage, signal) => {
    const response = await GetRequestCallAPI(`${MOSAIC_REQUEST_LIST_API}/${userLimit}/${currentPage}?search=${userSearch}&status=${location.pathname === "/mosaic-approved" ? 'approved':'requested'}`,accessToken,signal && signal);
    if (response?.status === 200) {
      dispatch(getMosaicRequestList(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getMosaicRequestList([]));
    } else {
      dispatch(getMosaicRequestList({}));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit, currentPage, controller.signal);
    return () => controller.abort();
  }, [userLimit, currentPage, userSearch]);

  const RequestUpdate = async (id, status) => {
    setLoading({ id: id, status: status });

    const payload = {
      request_id:id,
      status:"complete"
  };
    const response = await PostRequestAPI(`${MOSAIC_REQUEST_ACTION}`,payload, accessToken,false);
    if (response?.status === 200) {
      toast.success(response?.data?.msg);
      setLoading({ id: "", status: "" });
      fetchDataOfApprovalListing(userLimit, currentPage, userSearch);
    } else if (response?.status === 403) {
      CommonLogout();
      setLoading({ id: "", status: "" });
    } else {
      toast.error(response?.data?.error);
      setLoading({ id: "", status: "" });
    }
  };

  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Campaign Status</th>
                  <th>Earlier Mosaic</th>
                  <th>Earlier Mosaic Date</th>
                  <th>Source Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {mosaicRequestList && mosaicRequestList?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.campaign?.title}</td>
                          <td>{user?.campaign?.start_date ?  moment(user?.campaign?.start_date).format("LL"):"-"}</td>
                          <td> {user?.campaign?.end_date ?  moment(user?.campaign?.end_date).format("LL"):"-"} </td>
                          <td className="space-nowrap text-nowrap">
                              <Form className="switchbtn">
                                <Badge 
                                bg={getBadgeColor(user?.campaign?.status)} 
                                className={`text-capitalized`}>
                                {user?.campaign?.status==="pending" ? "draft" :user?.campaign?.status}</Badge>
                              </Form>
                              </td>
                          <td>{user?.earlier_mosaic?.receive_mosaic_earlier}</td>
                          <td>{user?.earlier_mosaic?.need_mosaic_by? moment(user?.earlier_mosaic?.need_mosaic_by).format("LL"):"-"}</td>
                          <td> {user?.source_image?.view_file_url ?
                           <div className="mosaic-img-box">
                             <img src={user?.source_image?.view_file_url} alt="Edit" className="inside-image-class" />
                           </div>:"-"}
                        </td>
                          <td>
                            {user?.status==="complete" ? "-":  
                            <div className="d-flex gap-2">
                              <ButtonComponent
                                title={"Confirm"}
                                color={"#34c759"}
                                onClick={()=>RequestUpdate(user?.id)}
                                isLoading={(loading?.id === user?.id) ?  true: false}
                              />
                            </div>}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {mosaicRequestList && mosaicRequestList?.data?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Campaign Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {parseInt(mosaicRequestList?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};
export default MosaicRequest;
