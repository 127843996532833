/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import MoreIcon from '../../assets/img/more_options.png';
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import GetUsersList from "../../Api/GetUsersList";
import { useSelector, useDispatch } from "react-redux";
import { getUserLists } from "../../redux/actions/usersActions";
import { Link } from "react-router-dom";
import CommonModal from "../Common/CommonModal";
import Avatar from "../Common/Avatar";
import CommonLogout from "../Common/CommonLogout";
import moment from "moment";
import CommonPagination from "../Common/CommonPagination";
import TooltipF from "../TooltipF";

const UserManagement = () => {
    const dispatch = useDispatch();
    const { accessToken, selfInfo } = useSelector((state) => state.personalReducers);
    const { userList } = useSelector((state) => state.userReducers);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page, userLimit,userType);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1, userLimit, e.target.value);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1, userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch ]);

    // Get data from api
    const fetchData = async (currentPage, userLimit,userType) => {
        try {
            const signal = abortController.signal;
        setCurrentPage(currentPage)
        const userList = await GetUsersList(accessToken, userSearch, userType, userLimit, currentPage, {signal });
            if(userList?.status === 200) {
                dispatch(getUserLists(userList.data));
                setPaginationLength(userList.pagination);
            } else if(userList?.status === 403) {
                CommonLogout();
                dispatch(getUserLists([]));
            } else {
                dispatch(getUserLists([]));
            };
        } catch (error) {
            if (error.name === 'AbortError') {
            // Request was cancelled due to component unmount or dependency change
            console.log('Request cancelled');
            } else {
            // Handle other errors
            console.error('Error fetching data:', error);
            }
        }
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // user active or deactive
    const UserActivStatus = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${user?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "User Active",
            Data: user
        });
    };
    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }

    return(<>
        <FiltersForm 
            filterType={"Users(first name, last name & email)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Picture	</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Created At</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList && userList?.map((user, index) => {
                                if(user?.id !== selfInfo?.id) {
                                    return(<React.Fragment key={index}>
                                        <tr className={activeTableDetails === user?.id ? "active" : ""}>
                                            <td style={{ width: '10px' }}>
                                                <button 
                                                    className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                    onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={activeTableDetails === user?.id ? true : false}
                                                >
                                                    <Image src={MoreIcon} alt="more icon" />
                                                </button>
                                            </td>
                                            <td>
                                                <Avatar type={"user"} src={user?.user_avatar} title={user?.first_name}/>
                                            </td>
                                            <td>{user?.first_name}</td>
                                            <td>{user?.last_name}</td>
                                            <td>{user?.email}</td>
                                            <td>
                                                {user?.phone === null ? "-" : "+"+user?.phone_code + " " + user?.phone}
                                            </td>
                                            <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                            <td>
                                                <Form className="switchbtn">
                                                    <Form.Check 
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={user?.is_active}
                                                        onChange={e => {}}
                                                        onClick={() => UserActivStatus(user)}
                                                    />
                                                    <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                                                </Form>
                                            </td>
                                            <td>
                                            <TooltipF title={"Edit"} placement={"left"}>
                                                <Link className="btn actionbtn" to={"/edit/user/" + user?.id} >
                                                    <Image src={EditIcon} alt="edit icon" />
                                                </Link>
                                            </TooltipF>
                                            </td>
                                        </tr>
                                        <Collapse in={activeTableDetails === user?.id ? true : false}>
                                            <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                                <td colSpan="8">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Country</th>
                                                                <td>{user?.country === null ? "-" : user?.country}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Registered At</th>
                                                                <td>{moment(user.created_at).format('LL')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Bank Details</th>
                                                                <td>-</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </Collapse>
                                    </React.Fragment>)
                                };
                            })}
                            {userList && userList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>User Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
    </>);
}

export default UserManagement;