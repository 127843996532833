const Data ={
     optionProfit : [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
     optionCampaignType : [
        { label: "Individual", value: "Individual" },
        { label: "Organization", value: "Organization" },
      ],
       contributions_work : [
        { label:"Open (people can contribute any amount)", value: "any" },
        {
            label:"Fixed (all contributions are the same amount)",
            value: "fixed",
        },
        {
            label:"Minimum (all contributions must start at a certain amount)",
            value: "minimum",
        },
        { 
            label:"Multiple Options (there wil be multiple contribution options)",
            value: "multiple",
        },
    ],
   Non_Profit:   [
        { "value": "Michigan Nature Association", "label": "Michigan Nature Association", "number": "38-6093404" },
        { "value": "Belle Isle Conservancy", "label": "Belle Isle Conservancy", "number": "23-7348118" },
        { "value": "Corewell Health Group", "label": "Corewell Health Group", "number": "38-1357020" },
        { "value": "Henry Ford Health System", "label": "Henry Ford Health System", "number": "38-1359601" },
        { "value": "Blue Care Network of Michigan", "label": "Blue Care Network of Michigan", "number": "38-2556178" },
        { "value": "Trinity Health - Michigan", "label": "Trinity Health - Michigan", "number": "38-1689625" },
        { "value": "Detroit Rescue Mission Ministries", "label": "Detroit Rescue Mission Ministries", "number": "38-1359581" },
        { "value": "Forgotten Harvest", "label": "Forgotten Harvest", "number": "38-2926476" },
        { "value": "Gleaners Community Food Bank", "label": "Gleaners Community Food Bank", "number": "38-2156255" },
        { "value": "United Way for Southeastern Michigan", "label": "United Way for Southeastern Michigan", "number": "38-1358200" },
        { "value": "Michigan Humane Society", "label": "Michigan Humane Society", "number": "38-1358206" },
        { "value": "The Kresge Foundation", "label": "The Kresge Foundation", "number": "38-1359761" },
        { "value": "Community Foundation for Southeast Michigan", "label": "Community Foundation for Southeast Michigan", "number": "38-2537729" },
        { "value": "The Skillman Foundation", "label": "The Skillman Foundation", "number": "38-1359517" },
        { "value": "Charles Stewart Mott Foundation", "label": "Charles Stewart Mott Foundation", "number": "38-1360553" },
        { "value": "Grand Rapids Community Foundation", "label": "Grand Rapids Community Foundation", "number": "38-6038121" },
        { "value": "Kalamazoo Community Foundation", "label": "Kalamazoo Community Foundation", "number": "38-6047512" },
        { "value": "Ann Arbor Area Community Foundation", "label": "Ann Arbor Area Community Foundation", "number": "23-7209152" },
        { "value": "Michigan Nonprofit Association", "label": "Michigan Nonprofit Association", "number": "38-2928220" },
        { "value": "Habitat for Humanity of Michigan", "label": "Habitat for Humanity of Michigan", "number": "38-3082318" },
        { "value": "American Red Cross", "label": "American Red Cross", "number": "53-0196605" },
        { "value": "United Way Worldwide", "label": "United Way Worldwide", "number": "13-1635294" },
        { "value": "Feeding America", "label": "Feeding America", "number": "36-3673599" },
        { "value": "Salvation Army USA", "label": "Salvation Army USA", "number": "58-0660607" },
        { "value": "Habitat for Humanity International", "label": "Habitat for Humanity International", "number": "91-1914868" },
        { "value": "St. Jude Children's Research Hospital", "label": "St. Jude Children's Research Hospital", "number": "62-0646012" },
        { "value": "Boys & Girls Clubs of America", "label": "Boys & Girls Clubs of America", "number": "13-5562976" },
        { "value": "YMCA of the USA", "label": "YMCA of the USA", "number": "36-3258696" },
        { "value": "Goodwill Industries International", "label": "Goodwill Industries International", "number": "53-0196517" },
        { "value": "American Cancer Society", "label": "American Cancer Society", "number": "13-1788491" }
    ],
     link_type : [
        {label:"Image",value:"image"},
        {label:"Video",value:"video"}
       ]  
    
}

export default Data