/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import ButtonComponent from "../Common/ButtonComponent";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import { CAMPAIGN_UPDATE_REQUEST_LIST } from "../Shared/constant";
import { getStatusColor } from "../Shared/utils";

import { getCampaignListForAfterApprove } from "../../redux/actions/campaignAction";
import CommonLogout from "../Common/CommonLogout";

const CampaignsUpdateRequest = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { campaignListForAfterApproval } = useSelector(
    (state) => state.campaignReducers
  );
  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});

  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  const fetchDataOfApprovalListing = async (userLimit, currentPage, signal) => {
    const response = await GetRequestCallAPI(
      `${CAMPAIGN_UPDATE_REQUEST_LIST}/${userLimit}/${currentPage}?search=${userSearch}&filter=approved`,
      accessToken,
      signal && signal
    );
    if (response?.status === 200) {
      dispatch(getCampaignListForAfterApprove(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getCampaignListForAfterApprove({data:[]}));
    }else{
      dispatch(getCampaignListForAfterApprove({data:[]}));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit, currentPage, controller.signal);
    return () => controller.abort();
  }, [userLimit, currentPage, userSearch]);

  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Request At</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaignListForAfterApproval && campaignListForAfterApproval?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.campaign?.title?  user?.campaign?.title :"-"}</td>
                          <td>
                            {user.created_at ?  moment(user.created_at).format("LL HH:mm A") :"-"}
                          </td>
                          <td>
                            {user.start_date?  moment(user.start_date).format("LL"):"-"}
                          </td>
                          <td>{user.end_date?  moment(user.end_date).format("LL"):"-"}</td>
                          <td>
                            <div className="d-flex gap-2">
                              <ButtonComponent
                                title={user?.status}
                                color={getStatusColor(user?.status)}
                                fontSize={"11px"}
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {campaignListForAfterApproval &&
                  campaignListForAfterApproval?.data?.length === 0 && (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        Campaign Not Found !
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(campaignListForAfterApproval?.pagination?.total_records) >
          10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};
export default CampaignsUpdateRequest;
