import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerCustom = ({
  label,
  name,
  selectedDate,
  onChange,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsStart = false,
  selectsEnd = false,
  placeholder = "MM/DD/YYYY",
  readOnly = false,
}) => {
  return (
    <div className="row">
      <div className="">
        <h4 className="label-required font-size">{label}</h4>
      </div>
      <div className="">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => onChange(name, date)}
          dateFormat="MM/dd/yyyy"
          className="form-control"
          wrapperClassName="d-block"
          placeholderText={placeholder ||"MM/DD/YYYY"}
          isClearable={!readOnly && selectedDate?true:false}
          name={name}
          readOnly={readOnly}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

export default DatePickerCustom;
