import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { callPreviewLink ,validateYtAndVimeo} from "../../Shared/utils";
const VideoURLInput = ({ label, onValidUrl, multiple = false, existingVideos = [] }) => {
    const [videoLink, setVideoLink] = useState("");
    const [videos, setVideos] = useState(existingVideos);
    const [error, setError] = useState("");

    const videoRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(shorts|watch)|youtu\.be|vimeo\.com)\/.*$/;

    const validateUrl = (url) => {
        if (!url) {
            setError("URL cannot be empty");
            return false;
        }
        if (!videoRegex.test(url)) {
            setError("Invalid YouTube or Vimeo URL");
            return false;
        }
        setError("");
        return true;
    };

    const handleInputChange = (e) => {
        setVideoLink(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === " " && videoLink.length === 0) {
            e.preventDefault();
        }
    };

    const handleSubmit = async () => {
        if (validateUrl(videoLink)) {
            const res = await validateYtAndVimeo(videoLink);
            const videoObj = { url: videoLink, thumbnail_url: res?.thumbnail_url };
    
            if (multiple) {
                const updatedVideos = [...videos, videoObj];  
                setVideos(updatedVideos);
                onValidUrl(updatedVideos);
            } else {
                setVideos([videoObj]);  
                onValidUrl(videoObj);
            }
            setVideoLink("");
        }
    };
    
    const handleRemoveVideo = (index) => {
        if (multiple) {
            const updatedVideos = videos.filter((_, i) => i !== index);
            setVideos(updatedVideos);
            onValidUrl(updatedVideos);
        } else {
            setVideos([]);
            onValidUrl("");
        }
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <h4 className="font-size">{label}</h4>
            </div>
            <div className="col-md-8">
                {/* Show input only if no single video exists */}
                {(!videos.length || multiple) && (
                    <div className="d-flex gap-4">
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                value={videoLink}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter YouTube or Vimeo Link"
                            />
                            {error && <div className="danger-colorset">{error}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Button 
                                onClick={handleSubmit} 
                                size="sm" 
                                style={{ borderRadius: "50px", width: "70px" }}
                            >
                                Add 
                            </Button>
                        </Form.Group>
                    </div>
                )}

                {/* Video Previews */}
                {multiple ? (
                    videos.length > 0 && (
                        <div className="d-flex flex-wrap gap-3">
                            {videos.map((video, index) => (
                                <div key={index} className="position-relative">
                                    {callPreviewLink(video?.url)}
                                    <button
                                        className="btn btn-danger btn-sm position-absolute top-0 end-0 video-remove"
                                        onClick={() => handleRemoveVideo(index)}
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                        </div>
                    )
                ) : (
                    videos.length > 0 && (
                        <div className="position-relative" style={{ width: "43%" }}>
                            {callPreviewLink(videos[0]?.url)}
                            <button
                                className="btn btn-danger btn-sm position-absolute top-0 end-0 video-remove"
                                onClick={() => handleRemoveVideo(0)}
                            >
                                X
                            </button>
                        </div>
                    )
                )}

                {/* Show "Add New Video" button if single video mode & no video */}
                {/* {!multiple && videos.length === 0 && (
                    <Button 
                        onClick={() => setVideos([])} 
                        size="sm" 
                        style={{ borderRadius: "50px", marginTop: "10px" }}
                    >
                        Add New Video
                    </Button>
                )} */}
            </div>
        </div>
    );
};

export default VideoURLInput;
