/* eslint-disable */
import React,{useState} from "react";
import { Nav, Image } from "react-bootstrap";
import DashBoardIcon from "../../assets/img/dashboard.png";
import UserIcon from "../../assets/img/group.png";
// import CharityIcon from "../../assets/img/charity.png";
// import BlogIcon from "../../assets/img/blogger.png";
// import BankIcon from "../../assets/img/bank.png";
import DonationIcon from "../../assets/img/donation.png";
import EmailIcon from "../../assets/img/Email.svg";
// import CategoryIcon from "../../assets/img/categories.png";
// import FundraiseIcon from "../../assets/img/fundraise.png";
// import BlogCategoryIcon from "../../assets/img/blog_category.png";
import ContactUsIcon from "../../assets/img/phone-call.png";
import FrameIcon from "../../assets/img/FrameIcon.svg";
import CampaignIcon from "../../assets/img/marketing.png";
import WrapIcon from "../../assets/img/wrap.png";
import WrapIconForApproval from "../../assets/img/wrap2.png";
import Payouts from "../../assets/img/payouts.png";
import SettingIcon from "../../assets/img/SettingIcon.svg";
import Approval from "../../assets/img/check.png";
import Campaign_Approval from "../../assets/img/ca.png";
import CampaignDataImg from "../../assets/img/clist.png";
import Arrow from "../../assets/img/careticons.png";

import Order_Icon from "../../assets/img/order-management-49.svg";
import Package_Icon from "../../assets/img/package-122.svg";
import Payment_Icon from "../../assets/img/payment-5.svg";
// import AthleteIcon from "../../assets/img/athlete.png";
import FundApplicationIcon from "../../assets/img/fundraise_applications.png";
import {CAMPAIGN_PAYOUTS_URL,CAMPAIGN_WRAP_UP_DONE_URL,MOSAIC_APPROVED_URL,CAMPAIGN_WRAP_UP_URL,MOSAIC_REQUEST_URL,CAMPAIGN_UPDATE_REQUEST_URL,CAMPAIGN_UPDATE_APPROVAL_URL,CAMPAIGN_APPROVAL_URL,PAYMENT_LIST,ORDER_LIST,PACKAGE_MANAGEMENT, DASHBOARD_URL, CAMPAIGN_MANAGEMENT_URL, USER_MANAGEMENT_URL, DONATIONS_MANAGEMENT_URL, CONTACT_MANAGEMENT_URL, FUNDRAISE_APPLICATION_MANAGEMENT_URL, MAIL_CAMPAIGN_MANAGEMENT, MOSAIC_MANAGEMENT, SETTING_MODULE } from '../../components/Shared/constant';
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import LogoHeader from "../../assets/img/Logo-reg.png";
import LogoHeaderSmall from "../../assets/img/Logo-White-New-small.png";

const Sidebar = () => {
    const Location = useLocation()?.pathname;
    const LocationEditCheck = Location.split('/')[1] +"/"+ Location.split('/')[2];
    const { sidebaropen } = useSelector((state) => state.personalReducers); 
    const { accessToken,is_mosaic,selfInfo } = useSelector((state) => state.personalReducers);
    const [isExpanded, setIsExpanded] = useState((Location === CAMPAIGN_APPROVAL_URL||Location === CAMPAIGN_UPDATE_APPROVAL_URL) ?  true : false);
    const [iscExpand, setCExpand] = useState((Location === CAMPAIGN_UPDATE_REQUEST_URL||Location === MOSAIC_REQUEST_URL || Location===CAMPAIGN_WRAP_UP_URL) ?  true : false);
    const toggleDropdown = () => {
        setIsExpanded(!isExpanded);
    };
    const toggleDropdown2 = () => {
        setCExpand(!iscExpand);
    };

    return(<div className={sidebaropen ? "sidebar active" : "sidebar"}>
        <div className="navbarHeader">
            {!sidebaropen ? (<Image src={LogoHeader} className="fulllogo" alt="logo" />) : (<Image src={LogoHeaderSmall} className="smallLogo" alt="logo" />)}
        </div>
        <Scrollbars 
            style={{ height: "calc(100vh - 72px)" }} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            <Nav defaultActiveKey={is_mosaic==="yes" ? MOSAIC_MANAGEMENT:DASHBOARD_URL} className="flex-column" style={{paddingLeft:"7px",paddingRight:"20px"}}>
            {
                is_mosaic==="yes" ? 
                <>
                
                <Link to={MOSAIC_MANAGEMENT} className={Location === MOSAIC_MANAGEMENT || Location?.includes(MOSAIC_MANAGEMENT) ? "nav-link active" : "nav-link"}>
                    <Image src={FrameIcon} alt="Users"/>
                    <span>Mosaics</span>
                </Link>
                {selfInfo?.permission==="mosaic-management,donation-management" &&(
                    <Link to={DONATIONS_MANAGEMENT_URL} className={Location === DONATIONS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={DonationIcon} alt="Users"/>
                    <span>Donations</span>
                    </Link>)}
                </>
                :
                <>
                <Link to={DASHBOARD_URL} className={(Location === DASHBOARD_URL || Location === "/") ? "nav-link active" : "nav-link"}>
                    <Image src={DashBoardIcon} alt="Dashboard"/>
                    <span>Dashboard</span>
                </Link>
                <Link to={FUNDRAISE_APPLICATION_MANAGEMENT_URL} className={Location === FUNDRAISE_APPLICATION_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={FundApplicationIcon} alt="Users"/>
                    <span>Campaign Applications</span>
                </Link>
                <Link to={PACKAGE_MANAGEMENT} className={Location === PACKAGE_MANAGEMENT ? "nav-link active" : LocationEditCheck === "edit/user" ? "nav-link active" : "nav-link"}>
                    <Image src={Package_Icon} alt="Users"/>
                    <span>Packages</span>
                </Link>
                <Link to={ORDER_LIST} className={Location === ORDER_LIST ? "nav-link active" : "nav-link"}>
                    <Image src={Order_Icon} alt="Users"/>
                    <span>Orders</span>
                </Link>
                <Link to={PAYMENT_LIST} className={Location === PAYMENT_LIST ? "nav-link active": "nav-link"}>
                    <Image src={Payment_Icon} alt="Users"/>
                    <span>Payments</span>
                </Link>


                {/* <Link to={CAMPAIGN_MANAGEMENT_URL} className={Location === CAMPAIGN_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/campaign" ? "nav-link active" : LocationEditCheck === "view/campaign" ? "nav-link active" : LocationEditCheck === "add/campaign" ? "nav-link active" : "nav-link"}>
                    <Image src={CampaignIcon} alt="Campaign Management"/>
                    <span>Campaigns</span>
                </Link> */}
                <div className="nav-link justify-content-between align-items-center" onClick={toggleDropdown2}>
                 <Link   className="text-decoration-none">
                 <Image src={CampaignIcon} alt="Campaign Management"/>
                    <span className="text-custom">Campaigns</span>
                 </Link>
                 <Image src={Arrow}
                 className={`transition-transform ${iscExpand ? "rotate-180" : ""} cursor-pointer new-arrow-class`} 
                  alt="Campaign Management"/>
                </div>
                {iscExpand &&(
                 <div className="d-flex px-3  flex-column">
                 <Link to={CAMPAIGN_MANAGEMENT_URL} className={Location === CAMPAIGN_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/campaign" ? "nav-link active" : LocationEditCheck === "view/campaign" ? "nav-link active" : LocationEditCheck === "add/campaign" ? "nav-link active" : "nav-link"}>
                    <Image src={CampaignDataImg} alt="Campaign Management"/>
                    <span>Campaign</span>
                </Link>
                 <Link to={CAMPAIGN_UPDATE_REQUEST_URL} className={Location === CAMPAIGN_UPDATE_REQUEST_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={CampaignDataImg} alt="Campaigns Update Request"/>
                    <span>Update Request</span>
                </Link>
                <Link to={MOSAIC_APPROVED_URL} className={Location === MOSAIC_APPROVED_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={FrameIcon} alt="Mosaic Requests"/>
                    <span>Mosaic Request</span>
                </Link>
                <Link to={CAMPAIGN_WRAP_UP_DONE_URL} className={Location === CAMPAIGN_WRAP_UP_DONE_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={WrapIcon} alt="Mosaic Requests"/>
                    <span>Warp Up Request</span>
                </Link>
                <Link to={CAMPAIGN_PAYOUTS_URL} className={Location === CAMPAIGN_PAYOUTS_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={Payouts} alt="Mosaic Requests"/>
                    <span>Campaign Payouts</span>
                </Link>
                 </div>
                )}
                <Link to={`${MAIL_CAMPAIGN_MANAGEMENT}`} className={(Location === MAIL_CAMPAIGN_MANAGEMENT || Location?.includes(MAIL_CAMPAIGN_MANAGEMENT)) ? "nav-link active" : "nav-link"}>
                <Image src={EmailIcon} alt="Campaign Mail Management"/>
                    <span>Campaign Mails</span>
                </Link>

                <div className="nav-link justify-content-between align-items-center" onClick={toggleDropdown}>
                 <Link   className="text-decoration-none">
                 <Image src={Approval} alt="Campaign Management"/>
                    <span className="text-custom">Approval</span>
                 </Link>
                 <Image src={Arrow}
                 className={`transition-transform ${isExpanded ? "rotate-180" : ""} cursor-pointer new-arrow-class`} 
                  alt="Campaign Management"/>
                </div>
                { isExpanded&&(
                    <div className="d-flex px-3  flex-column">
                <Link  to={CAMPAIGN_APPROVAL_URL} className={Location === CAMPAIGN_APPROVAL_URL ? "nav-link active" : "nav-link"}>
                    <Image src={Campaign_Approval} alt="Campaign Management"/>
                    <span>Campaign Approval</span>
                </Link>
                
                <Link to={CAMPAIGN_UPDATE_APPROVAL_URL} className={Location === CAMPAIGN_UPDATE_APPROVAL_URL ? "nav-link active" : "nav-link"}>
                    <Image src={Campaign_Approval} alt="Campaign Management"/>
                    <span>Update Approval</span>
                </Link>
                <Link to={MOSAIC_REQUEST_URL} className={Location === MOSAIC_REQUEST_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={FrameIcon} alt="Mosaic Requests"/>
                    <span>Mosaic Approval</span>
                </Link>
                 <Link to={CAMPAIGN_WRAP_UP_URL} className={Location === CAMPAIGN_WRAP_UP_URL ? "nav-link active" :  "nav-link"}>
                    <Image src={WrapIconForApproval} alt="Campaigns Warp Up"/>
                    <span>Warp Up Approval</span>
                </Link>
                    </div>
                )}
                <Link to={DONATIONS_MANAGEMENT_URL} className={Location === DONATIONS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={DonationIcon} alt="Users"/>
                    <span>Donations</span>
                </Link> 
                <Link to={USER_MANAGEMENT_URL} className={Location === USER_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/user" ? "nav-link active" : "nav-link"}>
                    <Image src={UserIcon} alt="Users"/>
                    <span>Users</span>
                </Link>
                {/* <Link to={CHARITY_MANAGEMENT_URL} className={Location === CHARITY_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/charity" ? "nav-link active" :  LocationEditCheck === "add/charity" ? "nav-link active" : LocationEditCheck === "view/charity" ? "nav-link active" : "nav-link"}>
                    <Image src={CharityIcon} alt="Users"/>
                    <span>Charity Management</span>
                </Link>
                <Link to={ATHLETE_MANAGEMENT_URL} className={Location === ATHLETE_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/athlete" ? "nav-link active" :  LocationEditCheck === "add/athlete" ? "nav-link active" : LocationEditCheck === "view/athlete" ? "nav-link active" : "nav-link"}>
                    <Image src={AthleteIcon} alt="Users"/>
                    <span>Athlete Management</span>
                </Link>
                <Link to={CATEGORY_MANAGEMENT_URL} className={Location === CATEGORY_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={CategoryIcon} alt="Users"/>
                    <span>Category Management</span>
                </Link>
                <Link to={FUNDRSISERS_MANAGEMENT_URL} className={Location === FUNDRSISERS_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/fundraiser" ? "nav-link active" : LocationEditCheck === "add/fundraiser" ? "nav-link active" : LocationEditCheck === "view/fundraiser" ? "nav-link active" : "nav-link"}>
                    <Image src={FundraiseIcon} alt="Users"/>
                    <span>Campaign Management</span>
                </Link>*/}
                {/* <Link to={BLOG_CATEGORY_MANAGEMENT_URL} className={Location === BLOG_CATEGORY_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={BlogCategoryIcon} alt="Admin Blog"/>
                    <span>Blog Category Management</span>
                </Link>
                <Link to={ADMIN_BLOG_MANAGEMENT_URL} className={Location === ADMIN_BLOG_MANAGEMENT_URL ? "nav-link active" : LocationEditCheck === "edit/blog" ? "nav-link active" : LocationEditCheck === "add/blog" ? "nav-link active" : "nav-link"}>
                    <Image src={BlogIcon} alt="Admin Blog"/>
                    <span>Admin Blog Management</span>
                </Link> */}
                <Link to={CONTACT_MANAGEMENT_URL} className={Location === CONTACT_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <Image src={ContactUsIcon} alt="Contact Management"/>
                    <span>Contacts</span>
                </Link>
                <Link to={MOSAIC_MANAGEMENT} className={Location === MOSAIC_MANAGEMENT || Location?.includes(MOSAIC_MANAGEMENT) ? "nav-link active" : "nav-link"}>
                    <Image src={FrameIcon} alt="Users"/>
                    <span>Mosaics</span>
                </Link>
                <Link to={SETTING_MODULE} className={Location === SETTING_MODULE || Location?.includes(SETTING_MODULE) ? "nav-link active" : "nav-link"}>
                    <Image src={SettingIcon} alt="Users"/>
                    <span>Settings</span>
                </Link>
                {/* <Link to={BANK_SECTION_URL} className={Location === BANK_SECTION_URL ? "nav-link active" : "nav-link"}>
                    <Image src={BankIcon} alt="Bank"/>
                    <span>Bank Section</span>
                </Link> */}
                </>
            }
            </Nav>
        </Scrollbars>
    </div>)
}

export default Sidebar;