import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function TooltipF(props) {
    const tooltip = (
        <Tooltip id="tooltip" style={{ position: "fixed" }}>
          {props?.title}
        </Tooltip>
    );
  return (
    <OverlayTrigger placement={props?.placement || "left"} overlay={tooltip}>
        {props?.children}
    </OverlayTrigger>
  )
}