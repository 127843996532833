/* eslint-disable */
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import UploadFile from "../../Api/UploadFile";
import { SelectCampaignTab } from "../../redux/actions/campaignAction";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import {
  API_URL_FOR_CREATING_COMPAIGN_URL,
  CAMPAIGN_MANAGEMENT_URL,
} from "../Shared/constant";
import { getPayloadCampaignAdd } from "../Shared/utils";
import Data from "./Component/Data";
import DatePickerCustom from "./Component/DatePickerCustom";
import FileUploader from "./Component/FileUploader";
import FormInput from "./Component/FormInput";
import FormSelectCustome from "./Component/FormSelectCustome";
import ModuleTitle from "./Component/ModuleTitle";
import SwitchCheckbox from "./Component/SwitchCheckbox";
import TextEditor from "./Component/TextEditor";
import VideoURLInput from "./Component/VideoURLInput";
import createSlug from "./CreateSlug";
import AddCampaign from "../../Api/AddCampaign";
import { toast } from "react-toastify";

const Add = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { accessToken } = useSelector((state) => state.personalReducers);
  const {
    campaignListIdWise,
    CurrenUrlBase,
    campaignList,
    campaignListForSequence,
  } = useSelector((state) => state.campaignReducers);
  const [prices, setPrices] = useState([]); // Start with an empty list
  const [newPrice, setNewPrice] = useState("");
  const [errorprice, setErrorPrice] = useState("");

  const addPrice = () => {
    const priceValue = parseFloat(newPrice);
    if (priceValue < 5) {
      setErrorPrice("Contribution Amount must be at least 5 $");
      return;
    }

    if (isNaN(priceValue) || newPrice.trim() === "") {
      setErrorPrice("Please enter a valid amount.");
      return;
    }

    if (prices.includes(priceValue)) {
      console.log("Amount is already added.");
      setErrorPrice("Amount is already added.");
      return;
    }

    setPrices([...prices, priceValue]);
    setNewPrice("");
    setErrorPrice("");
  };

  const removePrice = (index) => {
    const updatedPrices = prices.filter((_, i) => i !== index);
    setPrices(updatedPrices);
  };

  const [uploadingFiles, setUploadingFiles] = useState({
    source_image: false,
    signature_logo: false,
    campaign_file_source: false,
    campaign_second_file_source: false,
    campaign_file_source: false,
    non_profit_file_source: false,
    supporting_sponsor_logos: false,
    sponsor_logo: false,
    signature_logo: false,
    company_file_source: false,
  });
  const [formData, setFormData] = useState({
    avatar_validation: true,
    web_appearance: true,
    sequence_no: "",

    campaign_type: "",
    non_profit: "",
    ein_number: "",
    identify_non_profit: "",
    campaign_start_date: "",
    campaign_end_date: "",
    // start_date: null,
    // end_date: null,
    // receive_mosaic_earlier: "No",
    // need_mosaic_by: "",
    // mosaic_display_method: "",
    // mosaic_display_option: "",
    source_image: null,
    campaign_goal: "",
    financial_progress: true,
    contributions_key_type: "",
    contributions_value_type: "",
    contributions_option_list: [],
    // manage_campaign: "",
    // fundraising_campaign: "Yes",
    // partner_with_non_profit: "Yes",
    // campaign_users: [],
    // campaign_story: "",
    campaign_title: "",
    campaign_sub_title: "",
    campaign_description: "",
    campaign_file_type: "image",
    campaign_file_source: "",
    campaign_second_file_type: "image",
    campaign_second_file_source: [],
    // campaign_second: false,
    // company_website: "",
    company_title: "",
    company_description: "",
    company_file_type: "image",
    company_file_source: "",
    non_profit_website: "",
    non_profit_title: "",
    non_profit_description: "",
    non_profit_file_type: "image",
    non_profit_file_source: "",
    supporting_sponsors: "Yes",
    supporting_sponsors_campaign: "No",
    sponsor_signature: "Yes",
    sponsor_logo: "",
    signature_logo: [],
    upload_slogan: "",
    supporting_sponsor_logos: [],
    // email_subject: "Thank You for Your Support!",
    // email_body: `<div><p>Dear {name},</p><p>"Thank you for supporting {campaign_name}! Your participation plays a vital role in making a difference and helps bring this mission to life, whether through a donation or by joining in other ways."</p><p><br /></p><p>Every contribution moves us one step closer to achieving our goal, and we're grateful to have you as part of our community.</p><p><br /></p><p>Want to stay involved? Visit {website_link} to learn more about the impact of your donation and how you can continue to support this cause.</p><p><br /></p><p>With gratitude,</p><p>{campaing_owner_name}</p></div>`,
    // attack_signature_sponsor_logos: true,
    // privacy_policy: false,
    // terms_conditions: false,
  });
  const [error, setError] = useState({
    campaign_type: "",
    non_profit: "",
    ein_number: "",
    identify_non_profit: "",
    campaign_start_date: "",
    campaign_end_date: "",
    campaign_title: "",
    campaign_sub_title: "",
    campaign_file_type: "image",
    campaign_file_source: "",
    campaign_second_file_type: "image",
    campaign_second_file_source: "",
    company_title: "",
    non_profit_file_type: "",
    non_profit_title: "",
    supporting_sponsors: "",
    supporting_sponsors_campaign: "",
    company_file_type: "",
  });

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({ ...prev, [name]: date }));

    setError((prev) => ({ ...prev, [name]: date ? "" : prev[name] }));
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });

    if (name === "multiple") {
    }

    // Validation example
    if (!value) {
      setError((prev) => ({ ...prev, [name]: "This field is required" }));
    } else {
      setError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleTextChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onChecked = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileUpload = async (file, type, index) => {
    setUploadingFiles((prevState) => ({
      ...prevState,
      [type]: true,
    }));

    const formDataUpload = new FormData();
    formDataUpload.append("file", file);

    try {
      const response = await UploadFile(accessToken, formDataUpload);
      const payloadResponse = {
        file_url_400: response?.file_url_400,
        file_url_700: response?.file_url_700,
        view_file_url: response?.view_file_url,
      };
      if (response) {
        if (
          type === "signature_logo" ||
          type === "supporting_sponsor_logos" ||
          type === "campaign_second_file_source" ||
          type === "campaign_file_source"
        ) {
          setFormData((prev) => ({
            ...prev,
            [type]: [...(prev[type] || []), payloadResponse],
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            [type]: payloadResponse,
          }));
        }
        setError((prev) => ({
          ...prev,
          [type]: "",
        }));
      }
    } catch (error) {
      console.error("Upload failed", error);
    } finally {
      setUploadingFiles((prevState) => ({
        ...prevState,
        [type]: false, // Reset the loading state
      }));
    }
  };

  const handleDeleteFile = (type, index) => {
    if (
      type === "signature_logo" ||
      type === "supporting_sponsor_logos" ||
      type === "campaign_second_file_source"
    ) {
      setFormData((prev) => ({
        ...prev,
        [type]: prev[type].filter((_, i) => i !== index),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [type]: "",
      }));
    }
  };

  const AddCampaignFct2 = async () => {
    let errors = {};

    // Loop through formData keys and check for empty values
    // Object.keys(formData).forEach((key) => {
    //   if (!formData[key]?.toString().trim()) {
    //     errors[key] = "This field is required";
    //   }
    // });

    // if (formData.non_profit === "Yes") {
    //   ["identify_non_profit", "ein_number"].forEach((key) => {
    //     if (!formData[key]?.toString().trim()) {
    //       errors[key] = "This field is required";
    //     }
    //   });
    // }

    // if (Object.keys(errors)?.length > 0) {
    //   setError(errors);
    //   return;
    // }

    const payload = getPayloadCampaignAdd(formData);
    if (payload?.details?.supporting_sponsor_logos?.length > 0) {
      payload.has_sponsors = true;
    } else {
      payload.has_sponsors = false;
    }

    if (payload?.title) {
      payload.campaign_url = !formData.web_appearance
        ? `${API_URL_FOR_CREATING_COMPAIGN_URL}campaigns/${createSlug(
            payload.title
          )}`
        : null;
    }

    console.log("payload", payload);
    // const response = await AddCampaign(accessToken, payload);
    // if (response?.status === 200) {
    //   setTimeout(() => {
    //     Navigate(CAMPAIGN_MANAGEMENT_URL);
    //     toast.success(response.msg);
    //   }, 100);
    // } else {
    //   toast.error(response?.error || response.data.error);
    // }
  };

  return (
    <>
      <ul className="tabcomnsection">
        {CurrenUrlBase === "add-campaign" ? (
          <li
            className={CurrenUrlBase === "add-campaign" ? "active" : ""}
            onClick={() => dispatch(SelectCampaignTab("add-campaign"))}
          >
            Add
          </li>
        ) : (
          <>
            <li
              className={CurrenUrlBase === "edit-campaign" ? "active" : ""}
              onClick={() => dispatch(SelectCampaignTab("edit-campaign"))}
            >
              Edit
            </li>
          </>
        )}
        <div className="tabheadebuttns">
          {CurrenUrlBase === "edit-campaign" && (
            <Button className="savchanges">Save</Button>
          )}
          {CurrenUrlBase === "add-campaign" && (
            <Button className="savchanges" onClick={() => AddCampaignFct2()}>
              Add
            </Button>
          )}
          <Link
            className="btn savchanges linksmanage"
            to={CAMPAIGN_MANAGEMENT_URL}
          >
            Back
          </Link>
        </div>
      </ul>
      <div className="formsectionset">
        <div className="tabcontent">
          <Scrollbars
            style={{ height: "calc(100vh - 162px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="formloginset formeditset">
              <ModuleTitle title={"About You"} />

              <FormSelectCustome
                label="Are you an individual or an organization?"
                name="campaign_type"
                value={formData.campaign_type}
                options={Data?.optionCampaignType}
                onChange={handleChange}
                error={error?.campaign_type}
                required={true}
              />
              <FormSelectCustome
                label="Are you a non profit?"
                name="non_profit"
                value={formData.non_profit}
                options={Data?.optionProfit}
                onChange={handleChange}
                error={error?.non_profit}
                required={true}
              />
              {/* {formData?.non_profit == "Yes" && (
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8 d-flex p-0">
                    <FormInput
                      label=""
                      name="identify_non_profit"
                      value={formData.identify_non_profit}
                      onChange={handleChange}
                      onKeyDown={EmptySpaceFieldValid}
                      placeholder="Enter identify non profit"
                      maxLength={100}
                      error={error.identify_non_profit}
                      className=""
                    />
                    <FormInput
                      label=""
                      name="ein_number"
                      value={formData.ein_number}
                      onChange={handleChange}
                      onKeyDown={EmptySpaceFieldValid}
                      placeholder="Enter ein number"
                      maxLength={100}
                      error={error.ein_number}
                      className=""
                    />
                  </div>
                </div>
              )} */}
              <ModuleTitle title={"Timeline"} />

              <div className="row">
                <div className="col-md-4">
                  <div className="">
                    <h4 className="label-required font-size">Campaign Date</h4>
                  </div>
                </div>
                <div className="col-md-8 d-flex p-0">
                  <DatePickerCustom
                    label="Start Date"
                    name="campaign_start_date"
                    selectedDate={formData.campaign_start_date}
                    onChange={handleDateChange}
                    endDate={formData.campaign_end_date}
                    selectsStart={true}
                    error={error.campaign_start_date}
                    minDate={new Date()}
                  />

                  <DatePickerCustom
                    label="End Date"
                    name="campaign_end_date"
                    selectedDate={formData.campaign_end_date}
                    onChange={handleDateChange}
                    startDate={formData.campaign_start_date}
                    selectsEnd={true}
                    minDate={formData.campaign_start_date}
                    error={error.campaign_end_date}
                  />
                </div>
              </div>
              <ModuleTitle title={"Mosaic Display"} />
              <FileUploader
                label="Please upload the image you want to transform into a mosaic. You can always change this later, before the final mosaic is created.  This will be used to bring your campaign to life. "
                name="source_image"
                type="source_image"
                value={formData.source_image}
                onUpload={(file) => handleFileUpload(file, "source_image")}
                onDelete={() => handleDeleteFile("source_image")}
                uploadFileLoader={uploadingFiles.source_image}
                isMultiple={false}
              />

              <ModuleTitle title={"Contributions"} />
              <FormInput
                label="What is your financial goal for this campaign?"
                name="campaign_goal"
                value={formData.campaign_goal}
                onChange={handleChange}
                onKeyDown={EmptySpaceFieldValid}
                placeholder="Financial goal"
                maxLength={100}
                error={error.campaign_goal}
                labelClassName="col-md-4"
                divClassName="col-md-8"
              />
              <SwitchCheckbox
                label="Would you like a financial progress bar to appear on your campaign page?"
                checked={formData?.financial_progress}
                onChange={(e) =>
                  onChecked({
                    target: {
                      name: "financial_progress",
                      value: e.target.checked,
                    },
                  })
                }
                name="financial_progress"
                id="custom-switch-avatar-validation"
              />

              <FormSelectCustome
                label="How do you want contributions to work?"
                name="contributions_key_type"
                value={formData.contributions_key_type}
                options={Data?.contributions_work}
                onChange={handleChange}
                error={error?.contributions_key_type}
                required={true}
              />
              {formData.contributions_key_type === "fixed" && (
                <FormInput
                  label="Fixed Amount"
                  name="contributions_value_type"
                  value={formData.contributions_value_type}
                  onChange={handleChange}
                  onKeyDown={EmptySpaceFieldValid}
                  placeholder="Enter fixed amount"
                  maxLength={100}
                  error={error.contributions_value_type}
                  labelClassName="col-md-4"
                  divClassName="col-md-8"
                />
              )}
              {formData.contributions_key_type === "minimum" && (
                <FormInput
                  label="Minimum Amount"
                  name="contributions_value_type"
                  value={formData.contributions_value_type}
                  onChange={handleChange}
                  onKeyDown={EmptySpaceFieldValid}
                  placeholder="Enter minimum amount"
                  maxLength={100}
                  error={error.contributions_value_type}
                  labelClassName="col-md-4"
                  divClassName="col-md-8"
                />
              )}
              {formData.contributions_key_type === "multiple" && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="formgroups">
                        <h4 className="label font-size">
                          Multiple Amount type
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-8 gap-2 d-flex flex-column">
                      <div className="d-flex gap-2">
                        {prices
                          ?.sort((a, b) => a - b)
                          .map((price, index) => (
                            <div
                              key={index}
                              className="position-relative border border-warning rounded p-2 bg-light"
                              style={{ minWidth: "70px", textAlign: "center" }}
                            >
                              <span>${price}</span>
                              <Button
                                variant="danger"
                                size="sm"
                                className="position-absolute top-0 right-0 translate-middle rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  fontSize: "12px",
                                  padding: "0",
                                  right: "-15px",
                                }}
                                onClick={() => removePrice(index)}
                              >
                                &times;
                              </Button>
                            </div>
                          ))}
                      </div>
                      <div className="d-flex gap-2">
                        {prices?.length <= 4 && (
                          <>
                            <div className="d-flex flex-column gap-2">
                              <Form.Control
                                type="number"
                                value={newPrice}
                                onChange={(e) => setNewPrice(e.target.value)}
                                placeholder="Amount"
                                style={{ width: "200px" }}
                              />
                              {errorprice && (
                                <p
                                  style={{
                                    margin: "0px",
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {errorprice}
                                </p>
                              )}
                            </div>
                            <Button
                              size="sm"
                              style={{
                                borderRadius: "50px",
                                width: "200px",
                                height: "40px",
                              }}
                              onClick={addPrice}
                            >
                              Add Amount
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <ModuleTitle title={"Campaign Details"} />
              <FormInput
                label="Campaign Title"
                name="campaign_title"
                value={formData.campaign_title}
                onChange={handleChange}
                onKeyDown={EmptySpaceFieldValid}
                placeholder="Top of your webpage title."
                maxLength={100}
                error={error.campaign_title}
                labelClassName="col-md-4"
                divClassName="col-md-8"
                required={true}
              />
              <FormInput
                label="Campaign Subtitle"
                name="campaign_sub_title"
                value={formData.campaign_sub_title}
                onChange={handleChange}
                onKeyDown={EmptySpaceFieldValid}
                placeholder="A small summary beneath the title."
                maxLength={100}
                error={error.campaign_sub_title}
                labelClassName="col-md-4"
                divClassName="col-md-8"
                required={true}
              />

              <TextEditor
                label="Campaign Description"
                name="campaign_description"
                value={formData.campaign_description}
                onChange={handleTextChange}
                // onKeyDown={EmptySpaceFieldValid}
                error={error.campaign_description}
              />

              <FormSelectCustome
                label="Would you like a video or an image to be displayed above your description?"
                name="campaign_file_type"
                value={formData.campaign_file_type}
                options={Data?.link_type}
                onChange={handleChange}
                error={error?.campaign_file_type}
                required={true}
              />
              {formData.campaign_file_type === "image" && (
                <FileUploader
                  label="Upload Image or Logo"
                  name="campaign_file_source"
                  type="campaign_file_source"
                  value={formData.campaign_file_source}
                  onUpload={(file) =>
                    handleFileUpload(file, "campaign_file_source")
                  }
                  onDelete={(index) =>
                    handleDeleteFile("campaign_file_source", index)
                  }
                  uploadFileLoader={uploadingFiles.campaign_file_source}
                  isMultiple={true}
                />
              )}
              {formData.campaign_file_type === "video" && (
                <>
                  <VideoURLInput
                    label="Add Video"
                    onValidUrl={(url) =>
                      setFormData((prev) => ({
                        ...prev,
                        campaign_file_source: url,
                      }))
                    }
                  />
                </>
              )}

              <FormSelectCustome
                label="Would you like to add secondary images or videos underneath the description?"
                name="campaign_second_file_type"
                value={formData.campaign_second_file_type}
                options={Data?.link_type}
                onChange={handleChange}
                error={error?.campaign_second_file_type}
                required={true}
              />
              {formData.campaign_second_file_type === "image" && (
                <FileUploader
                  label="Upload Image or Logo"
                  name="campaign_second_file_source"
                  type="campaign_second_file_source"
                  value={formData.campaign_second_file_source}
                  onUpload={(file) =>
                    handleFileUpload(file, "campaign_second_file_source")
                  }
                  onDelete={(index) =>
                    handleDeleteFile("campaign_second_file_source", index)
                  }
                  uploadFileLoader={uploadingFiles.campaign_second_file_source}
                  isMultiple={true}
                />
              )}
              {formData.campaign_second_file_type === "video" && (
                <VideoURLInput
                  label="Add Video"
                  multiple
                  onValidUrl={(urls) =>
                    setFormData((prev) => ({
                      ...prev,
                      campaign_second_file_source: urls,
                    }))
                  }
                />
              )}

              <SwitchCheckbox
                label="Avatar Validation"
                checked={formData?.avatar_validation}
                onChange={(e) =>
                  onChecked({
                    target: {
                      name: "avatar_validation",
                      value: e.target.checked,
                    },
                  })
                }
                name="avatar_validation"
                id="avatar_validation"
              />
              <SwitchCheckbox
                label="Web Appearance"
                checked={formData?.web_appearance}
                onChange={(e) =>
                  onChecked({
                    target: {
                      name: "web_appearance",
                      value: e.target.checked,
                    },
                  })
                }
                name="web_appearance"
                id="web_appearance"
              />
              {formData?.web_appearance && (
                <FormInput
                  label="Sequence Number"
                  name="sequence_no"
                  value={formData.sequence_no}
                  onChange={handleChange}
                  onKeyDown={EmptySpaceFieldValid}
                  placeholder="Enter Sequence number"
                  maxLength={100}
                  error={error.sequence_no}
                  labelClassName="col-md-4"
                  divClassName="col-md-8"
                  required={true}
                />
              )}

              <ModuleTitle title={"Your Details"} />
              <FormInput
                label="Your Name"
                name="company_title"
                value={formData.company_title}
                onChange={handleChange}
                onKeyDown={EmptySpaceFieldValid}
                placeholder="Enter the name you'd like to be recognized by."
                maxLength={100}
                error={error.company_title}
                labelClassName="col-md-4"
                divClassName="col-md-8"
                required={true}
              />
              <TextEditor
                label="Your Description"
                name="company_description"
                value={formData.company_description}
                onChange={handleTextChange}
                // onKeyDown={EmptySpaceFieldValid}
                error={error.company_description}
                placeholder="Share a little about yourself or your project."
              />
              <FormSelectCustome
                label="Would you like a video or an image to be displayed next to your description?"
                name="company_file_type"
                value={formData.company_file_type}
                options={Data?.link_type}
                onChange={handleChange}
                error={error?.company_file_type}
                required={true}
              />
              {formData?.company_file_type === "image" && (
                <FileUploader
                  label="Upload Image or Logo"
                  name="company_file_source"
                  type="company_file_source"
                  value={formData.company_file_source}
                  onUpload={(file) =>
                    handleFileUpload(file, "company_file_source")
                  }
                  onDelete={(index) =>
                    handleDeleteFile("company_file_source", index)
                  }
                  uploadFileLoader={uploadingFiles.company_file_source}
                  isMultiple={false}
                />
              )}
              {formData.company_file_type === "video" && (
                <>
                  <VideoURLInput
                    label="Add Video"
                    onValidUrl={(url) =>
                      setFormData((prev) => ({
                        ...prev,
                        company_file_source: url,
                      }))
                    }
                  />
                </>
              )}

              {formData.non_profit === "Yes" && (
                <>
                  <ModuleTitle title={"Non-Profit Details"} />
                  <FormInput
                    label="Non-Profit Name"
                    name="non_profit_title"
                    value={formData.non_profit_title}
                    onChange={handleChange}
                    onKeyDown={EmptySpaceFieldValid}
                    placeholder="What’s the name of the non-profit?"
                    maxLength={100}
                    error={error.non_profit_title}
                    labelClassName="col-md-4"
                    divClassName="col-md-8"
                    required={true}
                  />
                  <TextEditor
                    label="Non-Profit Description"
                    name={"non_profit_description"}
                    value={formData.non_profit_description}
                    onChange={handleTextChange}
                    // onKeyDown={EmptySpaceFieldValid}
                    error={error.non_profit_description}
                    placeholder="Tell us about the mission or purpose of the non-profit."
                  />
                  <FormSelectCustome
                    label="Would you like a video or an image to be displayed next to your description?"
                    name="non_profit_file_type"
                    value={formData.non_profit_file_type}
                    options={Data?.link_type}
                    onChange={handleChange}
                    error={error?.non_profit_file_type}
                    required={true}
                  />
                  {formData?.non_profit_file_type === "image" && (
                    <FileUploader
                      label="Upload Image or Logo"
                      name="non_profit_file_source"
                      type="non_profit_file_source"
                      value={formData.non_profit_file_source}
                      onUpload={(file) =>
                        handleFileUpload(file, "non_profit_file_source")
                      }
                      onDelete={(index) =>
                        handleDeleteFile("non_profit_file_source", index)
                      }
                      uploadFileLoader={uploadingFiles.non_profit_file_source}
                      isMultiple={false}
                    />
                  )}
                  {formData.non_profit_file_type === "video" && (
                    <>
                      <VideoURLInput
                        label="Add Video"
                        onValidUrl={(url) =>
                          setFormData((prev) => ({
                            ...prev,
                            non_profit_file_source: url,
                          }))
                        }
                      />
                    </>
                  )}
                </>
              )}

              <ModuleTitle title={"Sponsors"} />
              <FormSelectCustome
                label="Do you have any paying or supporting sponsors?"
                name="supporting_sponsors"
                value={formData?.supporting_sponsors}
                options={Data?.optionProfit}
                onChange={handleChange}
                error={error?.supporting_sponsors}
                required={true}
              />
              {formData?.supporting_sponsors === "Yes" && (
                <>
                  <FormSelectCustome
                    label="Do you have sponsors that have made monetary or significant contributions to your campaign as of today that require recognition on your campaign page and thank you email?"
                    name="sponsor_signature"
                    value={formData?.sponsor_signature}
                    options={Data?.optionProfit}
                    onChange={handleChange}
                    error={error?.sponsor_signature}
                    required={true}
                  />
                  {formData?.sponsor_signature === "Yes" && (
                    <>
                      <FileUploader
                        label="Upload Presenting Sponsor Logo"
                        name="sponsor_logo"
                        type="sponsor_logo"
                        value={formData.sponsor_logo}
                        onUpload={(file) =>
                          handleFileUpload(file, "sponsor_logo")
                        }
                        onDelete={(index) =>
                          handleDeleteFile("sponsor_logo", index)
                        }
                        uploadFileLoader={uploadingFiles.sponsor_logo}
                        isMultiple={false}
                      />
                      <FileUploader
                        label="Upload Signature Sponsor Logos"
                        name="signature_logo"
                        type="signature_logo"
                        value={formData.signature_logo}
                        onUpload={(file) =>
                          handleFileUpload(file, "signature_logo")
                        }
                        onDelete={(index) =>
                          handleDeleteFile("signature_logo", index)
                        }
                        uploadFileLoader={uploadingFiles.signature_logo}
                        isMultiple={true}
                      />
                    </>
                  )}

                  <ModuleTitle title={"Supporting Sponsors"} />
                  <FormSelectCustome
                    label="Do you have any supporting sponsors that have made, or plan to make non-monetary contributions to your campaign?"
                    name="supporting_sponsors_campaign"
                    value={formData?.supporting_sponsors_campaign}
                    options={Data?.optionProfit}
                    onChange={handleChange}
                    error={error?.supporting_sponsors_campaign}
                    required={true}
                  />
                  {formData?.supporting_sponsors_campaign == "Yes" && (
                    <FileUploader
                      label="Upload Supporting Sponsor Logos"
                      name="supporting_sponsor_logos"
                      type="supporting_sponsor_logos"
                      value={formData.supporting_sponsor_logos}
                      onUpload={(file) =>
                        handleFileUpload(file, "supporting_sponsor_logos")
                      }
                      onDelete={(index) =>
                        handleDeleteFile("supporting_sponsor_logos", index)
                      }
                      uploadFileLoader={uploadingFiles.supporting_sponsor_logos}
                      isMultiple={true}
                    />
                  )}
                </>
              )}

              <ModuleTitle title={"Contributions"} />
              <FormInput
                label="Personalize your “Upload Slogan” "
                name="upload_slogan"
                value={formData.upload_slogan}
                onChange={handleChange}
                onKeyDown={EmptySpaceFieldValid}
                placeholder="Create a slogan"
                maxLength={100}
                error={error.upload_slogan}
                labelClassName="col-md-4"
                divClassName="col-md-8"
                required={true}
              />
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
export default Add;
