/* eslint-disable */
import React from "react";
import { Dropdown, Image } from 'react-bootstrap';
import DummyUserImage from "../../assets/img/dummyimage.png";
import Bell from "../../assets/img/bell.png";
import SidebarToggle from "../../assets/img/menu.png";
import { useDispatch, useSelector } from "react-redux";
import { sidebarClose } from "../../redux/actions/personalActions";
import { useLocation } from "react-router-dom";
import { CAMPAIGN_PAYOUTS_URL,CAMPAIGN_WRAP_UP_DONE_URL,MOSAIC_EDIT,MOSAIC_APPROVED_URL,CAMPAIGN_WRAP_UP_URL,MOSAIC_REQUEST_URL,CAMPAIGN_UPDATE_REQUEST_URL,CAMPAIGN_UPDATE_APPROVAL_URL,CAMPAIGN_APPROVAL_URL,ORDER_LIST,PAYMENT_LIST,PACKAGE_MANAGEMENT,LOGIN_URL, DASHBOARD_URL, FUNDRAISE_APPLICATION_MANAGEMENT_URL , CAMPAIGN_MANAGEMENT_URL, CHARITY_MANAGEMENT_URL, ATHLETE_MANAGEMENT_URL, USER_MANAGEMENT_URL, FUNDRSISERS_MANAGEMENT_URL, CATEGORY_MANAGEMENT_URL, DONATIONS_MANAGEMENT_URL, FUND_REQUEST_MANAGEMENT_URL, ADMIN_BLOG_MANAGEMENT_URL,BLOG_CATEGORY_MANAGEMENT_URL, BANK_SECTION_URL, CONTACT_MANAGEMENT_URL, MAIL_CAMPAIGN_MANAGEMENT, TEMPLATE_SUCCESS_POPUP, MOSAIC_MANAGEMENT, SETTING_MODULE, FREE_COUPON } from "./constant";
import LogoutAPI from "../../Api/LogoutAPI";
import { clearRedux, checkLogin } from "../../redux/actions/personalActions";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
    const dispatch = useDispatch();
    const Location = useLocation()?.pathname;

      const { campaignListForApproval,campaignListForUpdateApproval,mosaicRequestList,wrapUpRequestList } = useSelector(
        (state) => state.campaignReducers
      );

    const { sidebaropen, selfInfo, accessToken } = useSelector((state) => state.personalReducers); 
    const { fundraiseListIdWise } = useSelector((state) => state.fundraiseReducers);
    const { charityListIdWise } = useSelector((state) => state.charityReducers);
    const { athleteListIdWise } = useSelector((state) => state.athleteReducers);
    const { campaignListIdWise } = useSelector((state) => state.campaignReducers);

    const Navigate = useNavigate();

    // page title get on header
    const PageTitle = () => {
        if(Location === DASHBOARD_URL || Location === "/") {
            return "Dashboard";
        } else if(Location === USER_MANAGEMENT_URL) {
            return "Users";
        } else if(Location === CHARITY_MANAGEMENT_URL) {
            return "Charity";    
        } else if(Location === FUNDRSISERS_MANAGEMENT_URL) {
            return "Campaigns";
        } else if(Location === CATEGORY_MANAGEMENT_URL) {
            return "Categorys";
        } else if(Location === DONATIONS_MANAGEMENT_URL) {
            return "Donations";
        } else if(Location === FUND_REQUEST_MANAGEMENT_URL) {
            return "Fund Request";
        }else if(Location === PACKAGE_MANAGEMENT) {
            return "Packages";
        } else if(Location === ORDER_LIST){
            return "Orders"
        }else if(Location === PAYMENT_LIST){
            return "Payments"
        }else if(Location === ADMIN_BLOG_MANAGEMENT_URL) {
            return "Admin Blog";
        } else if(Location === BLOG_CATEGORY_MANAGEMENT_URL) {
            return "Blog Category";
        } else if (Location === BANK_SECTION_URL){
            return "Bank Section";
        } else if (Location === CONTACT_MANAGEMENT_URL) {
            return "Contacts"
        } else if (Location === ATHLETE_MANAGEMENT_URL) {
            return "Athletes"
        } else if ( Location?.includes(`/add${MAIL_CAMPAIGN_MANAGEMENT}`)) {
            return "Add Mail Campaign"
        } else if ( Location?.includes(`/edit${MAIL_CAMPAIGN_MANAGEMENT}`)) {
            return "Edit Mail Campaign"
        } else if (Location === MAIL_CAMPAIGN_MANAGEMENT) {
            return "Campaign Mails"
        }else if (Location === TEMPLATE_SUCCESS_POPUP || Location?.includes(TEMPLATE_SUCCESS_POPUP)) {
            return "Template Success Popup"
        } else if (Location === FUNDRAISE_APPLICATION_MANAGEMENT_URL) {
            return "Campaign Applications"
        } else if (Location === CAMPAIGN_MANAGEMENT_URL) {
            return "Campaigns"
        }
         else if (Location === CAMPAIGN_APPROVAL_URL) {
            return "Campaigns Approval"
        }
         else if (Location === CAMPAIGN_UPDATE_APPROVAL_URL) {
            return "Update Approval"
        }
         else if (Location === MOSAIC_REQUEST_URL) {
            return "Mosaic Approval"
        }
         else if (Location === MOSAIC_APPROVED_URL) {
            return "Mosaic Request"
        }
         else if (Location === CAMPAIGN_UPDATE_REQUEST_URL) {
            return "Update Request"
        }
         else if (Location === CAMPAIGN_WRAP_UP_URL) {
            return "Wrap Up Approval"
        }
         else if (Location === CAMPAIGN_WRAP_UP_DONE_URL) {
            return "Wrap Up Request"
        }
        else if(Location === MOSAIC_MANAGEMENT){
            return "Mosaics"
        }
        else if(Location === CAMPAIGN_PAYOUTS_URL){
            return "Campaign Payouts"
        }
        else if(Location?.includes(MOSAIC_EDIT)){
            return "Mosaics Request"
        }
        else if(Location === SETTING_MODULE || Location?.includes(SETTING_MODULE)){
            return "Settings"
        }
         else {
            const LocationLink = Location.split('/')[1] + "/" + Location.split('/')[2];
            const FundraiseUrl = fundraiseListIdWise[Location.split('/')[Location.split('/').length - 1]];
            const CharityUrl = charityListIdWise[Location.split('/')[Location.split('/').length - 1]];
            const AthleteUrl = athleteListIdWise[Location.split('/')[Location.split('/').length - 1]];
            const CampaignUrl = campaignListIdWise[Location.split('/')[Location.split('/').length - 1]];

            if(LocationLink === "edit/user") {
                return "Edit User";
            } else if(LocationLink === "edit/fundraiser") {
                return FundraiseUrl?.title;
            } else if(LocationLink === "view/charity" || LocationLink === "edit/charity") {
                return CharityUrl?.name;
            } else if(LocationLink === "add/charity") {
                return "Add Charity";
            } else if(LocationLink === "add/fundraiser") {
                return "Add Fundraise";
            } else if(LocationLink === "view/fundraiser") {
                return FundraiseUrl?.title;
            } else if(LocationLink === "edit/blog") {
                return "Edit Blog";
            } else if(LocationLink === "add/blog") {
                return "Add Blog";
            } else if(LocationLink === "view/athlete") {
                return AthleteUrl?.name;
            } else if(LocationLink === "edit/athlete") {
                return AthleteUrl?.name;
            } else if(LocationLink === "view/campaign") {
                return CampaignUrl?.title;
            } else if(LocationLink === "add/campaign") {
                return "Add Campaign";
            } else if(LocationLink === "edit/campaign") {
                return CampaignUrl?.title;
            } else if(LocationLink === "add/mosaic-management"){
                return "Add Mosaic";
            }else if(LocationLink === "add/package"){
                return "Add Package";
            }else if(LocationLink === `add${FREE_COUPON}`){
                return "Add Coupon";
            }else if(LocationLink === `update${FREE_COUPON}`){
                return "Update Package";
            }else if(LocationLink === "package/update"){
                return "Update Package";
            }else if(LocationLink === "order/list"){
                return "Order Details";
            }
        }
    };

    // logout function
    const Logout = async () => {
        const responce = await LogoutAPI(accessToken);
        if(responce?.status === 200){
            toast.success(responce.msg);
            dispatch(clearRedux());
            dispatch(checkLogin('no'));
            Navigate(LOGIN_URL);
        } else if(responce.status === 403) {
            toast.error(responce.data.error);
            dispatch(clearRedux());
            dispatch(checkLogin('no'));
            Navigate(LOGIN_URL);
        };
    };

    return(<header className="headeradmin">
        <button className="toglebutton" onClick={() => dispatch(sidebarClose(!sidebaropen))}>
            <Image src={SidebarToggle} className="rounded" alt="toggle button"/>
        </button>
        <h6 className="pagetitle">{PageTitle()}</h6>
        <div className="d-flex p-2 align-items-center cursor-pointer">
        {/* <Dropdown>
            <Dropdown.Toggle id="notification" className="bell-class">
            <div className="size-16">
        <Image src={Bell} style={{width:'100%'}} alt="Bell" className="rounded"/>
        </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                    <Dropdown.Item className="dropdown-style align-items-center" onClick={()=>Navigate("/campaign-approval")}>Campaigns Approval <div className="notification-count"><span>{campaignListForApproval?.data?.length}</span></div></Dropdown.Item>
                    <Dropdown.Item className="dropdown-style align-items-center" onClick={()=>Navigate("/campaign-update-approval")}>Update Approval <div className="notification-count"><span>{campaignListForUpdateApproval?.data?.length}</span></div></Dropdown.Item>
                </Dropdown.Menu>
        </Dropdown>       */}
        </div>
        <div className="userdropdown">
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="text-capitalize">
                    <Image src={selfInfo?.user_avatar === null ? DummyUserImage : selfInfo?.is_s3_url} className="rounded" alt="user"/>
                    {selfInfo?.email?.split('@')[0]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </header>)
}

export default Header;