import React from 'react';
import { Form, Badge } from 'react-bootstrap';

const SwitchCheckbox = ({ label, checked, onChange, name, id }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="formgroups">
          <h4 className="label font-size">{label}</h4>
        </div>
      </div>
      <div className="col-md-8">
        <Form className="switchbtn">
          <Form.Check 
            type="switch"
            id={id}
            checked={checked}
            name={name}
            onChange={onChange}
          />
          <Badge bg={checked ? "primary" : "danger"}>{checked ? "Yes" : "No"}</Badge>
        </Form>
      </div>
    </div>
  );
};

export default SwitchCheckbox;
