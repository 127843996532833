import React from "react";
import { Spinner } from 'react-bootstrap';


const ButtonComponent = ({ title,onClick,isLoading,color,fontSize}) => {
  return (
    <button disabled={isLoading} onClick={onClick} className={"button-class-name"} style={{ backgroundColor: color,fontSize:fontSize,cursor:fontSize ? "auto":"pointer"}}>
       {title} {isLoading&&<Spinner className="new_spinner_classname"/>}  
    </button>
  );
};

export default ButtonComponent;
