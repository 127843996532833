/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Image, Collapse } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import MoreIcon from '../../assets/img/more_options.png';
import ViewIcon from '../../assets/img/show.png';
import Form from 'react-bootstrap/Form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Badge from 'react-bootstrap/Badge';
import FiltersForm from "../Common/FiltersForm";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CommonModal from "../Common/CommonModal";
import CommonLogout from "../Common/CommonLogout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GetpaymentList from "../../Api/GetpaymentList";
import { getPaymentList } from "../../redux/actions/paymentAction";
import PaymentItems from "./PaymentItems";
import CommonPagination from "../Common/CommonPagination";


const PaymentListManagement = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const {paymentList} = useSelector((state)=>state.paymentReducers)
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType)
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        fetchData(1,userLimit,e.target.value);
        setCurrentPage(1);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType);
         return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch]);

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)
        const responce = await GetpaymentList(accessToken, userSearch, userType, userLimit, currentPage, {signal});
        if(responce?.status === 200) {
            dispatch(getPaymentList(responce.data));
            setPaginationLength(responce.pagination);
        } else if(responce?.status === 403) {
            CommonLogout();
            dispatch(getPaymentList([]));
        } else {
            dispatch(getPaymentList([]));
        };
    };

    
    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType)
    }
    
    return(
        <>
               <FiltersForm 
            filterType={"Payment List"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div className="tableContent">
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Transactions Id</th>
                                <th>Payment Type</th>
                                <th>Amount</th>
                             
                                <th>Coupon</th>
                                <th>Status</th> 
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentList && paymentList?.map((element, index) => {
                                return(<React.Fragment key={index}>
                                <PaymentItems  element={element} />
                            </React.Fragment>)
                            })}
                            {paymentList && paymentList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Payment Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
  
  
              {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}
        </div>

        </>
    )
}
export default PaymentListManagement