/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetRequestCallAPI } from "../../Api/GetRequest";
import ButtonComponent from "../Common/ButtonComponent";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import { CAMPAIGN_UPDATE_PARTICULAR_REQUEST, CAMPAIGN_UPDATE_REQUEST_LIST } from "../Shared/constant";
import { getCampaignListForUpdateApproval } from "../../redux/actions/campaignAction";


 const CampaignUpdateApproval = () => {
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { campaignListForUpdateApproval } = useSelector(
      (state) => state.campaignReducers
    );
    const [userSearch, setUserSearch] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationLength, setPaginationLength] = useState({});
    const [loading, setLoading] = useState({id: "",status:""});
    
  
    const currentFunction = (page) => {
      setCurrentPage(page);
    };
    const callUserLimit = (e) => {
      setUserLimit(e);
    };
    const ClearSearchFiled = () => {
      setUserSearch("");
    };
  
    const fetchDataOfApprovalListing = async (userLimit, currentPage,signal) => {
      const response = await GetRequestCallAPI(`${CAMPAIGN_UPDATE_REQUEST_LIST}/${userLimit}/${currentPage}?search=${userSearch}&filter=pending`,accessToken, signal && signal );
      if (response?.status === 200) {
        dispatch(getCampaignListForUpdateApproval(response?.data));
        setPaginationLength(response?.data?.pagination);
      } else if (response?.status === 403) {
        CommonLogout();
        dispatch(getCampaignListForUpdateApproval({data:[]}));
      } else {
        dispatch(getCampaignListForUpdateApproval({data:[]}));
      }
    };
  
  useEffect(() => {
      const controller = new AbortController();
      fetchDataOfApprovalListing(userLimit, currentPage, controller.signal);    
      return () => controller.abort(); 
    }, [userLimit, currentPage, userSearch]);
  
  
    const RequestUpdate  =async (id,status) =>{
      setLoading({id:id,status:status})
      const response = await GetRequestCallAPI(`${CAMPAIGN_UPDATE_PARTICULAR_REQUEST}/${id}/${status}`,accessToken)
      if (response?.status === 200) {
          toast.success(response?.data?.msg);
          setLoading({id: "",status: "",});
          fetchDataOfApprovalListing(userLimit, currentPage,userSearch);
      } else if (response?.status === 403) {
          CommonLogout();
          setLoading({id: "",status: "",});
      } else {
          toast.error(response?.data?.error);
          setLoading({id: "",status: "",});
      }
    }
  return (
    <>
      <FiltersForm 
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
            />
    <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Message</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaignListForUpdateApproval && campaignListForUpdateApproval?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                      <tr>
                       <td>{ user?.campaign?.title? user?.campaign?.title :"-"}</td>
                       <td style={{width:'600px'}}>{user?.message?  user?.message :"-"}</td>
                       <td>{moment(user.created_at).format("LL HH:mm A")}</td>
                      <td>
                      <div className="d-flex gap-2">
                       <ButtonComponent title={"Approve"} color={"#34c759"} isLoading={(loading?.id === user?.id&&loading?.status==="approved") ?  true: false} 
                       onClick={()=>RequestUpdate(user?.id,"approved")}
                        />
                       <ButtonComponent title={"Reject"} color={"#ff3b30"} isLoading={(loading?.id === user?.id &&loading?.status==="rejected" ) ?  true: false} 
                       onClick={()=>RequestUpdate(user?.id,"rejected")}
                       />            
                       </div>
                       </td>
                     </tr>
                      </React.Fragment>
                    );
                  })}
                {campaignListForUpdateApproval &&
                    campaignListForUpdateApproval?.data?.length === 0 && (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        Campaign Not Found !
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(campaignListForUpdateApproval?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  )
}

export default  CampaignUpdateApproval