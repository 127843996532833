/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { getWrapUpRequestListComplete } from "../../redux/actions/campaignAction";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import FiltersForm from "../Common/FiltersForm";
import { WRAPPING_REQUEST_LIST_API } from "../Shared/constant";
import { getBadgeColor } from "../Shared/utils";


const CampaignsWrapUpRequestDone = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.personalReducers);
  const { wrapUpRequestListApprove } = useSelector((state) => state.campaignReducers);

  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLength, setPaginationLength] = useState({});



  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const callUserLimit = (e) => {
    setUserLimit(e);
  };
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  const fetchDataOfApprovalListing = async (userLimit,currentPage,userSearch,signal) => {
    const response = await GetRequestCallAPI(`${WRAPPING_REQUEST_LIST_API}/${userLimit}/${currentPage}?search=${userSearch}&status=complete`,accessToken, signal && signal);
    if (response?.status === 200) {
      dispatch(getWrapUpRequestListComplete(response?.data));
      setPaginationLength(response?.data?.pagination);
    } else if (response?.status === 403) {
      CommonLogout();
      dispatch(getWrapUpRequestListComplete({data: [],}));
    } else {
      dispatch(getWrapUpRequestListComplete({data: [],}));}
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataOfApprovalListing(userLimit, currentPage, userSearch,controller.signal);
    return () => controller.abort();
  }, [userLimit, currentPage, userSearch]);

  return (
    <>
      <FiltersForm
        isNotActive={true}
        filterType={"Campaign Name"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setUserLimit={callUserLimit}
        ClearSearchFiled={ClearSearchFiled}
      />
      <div className="tableContent">
        <Scrollbars
          style={{ height:paginationLength?.total_records > 10 ? "calc(100% - 87px)": "calc(100% - 50px)"}}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Campaign Goal</th>
                  <th>Total Contributions</th>
                  <th>Message</th>
                  <th>Campaign Status</th>
                </tr>
              </thead>
              <tbody>
                {wrapUpRequestListApprove && wrapUpRequestListApprove?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.campaign?.title ? user?.campaign?.title : "-"} </td>
                          <td>{user?.campaign?.campaign_goal? `${Number(user?.campaign?.campaign_goal / 100)?.toFixed(2)}`: "-"}</td>
                          <td>{user?.campaign?.total_contributions? `${Number(user?.campaign?.total_contributions / 100)?.toFixed(2)}`: "-"}</td>
                          <td>{user?.message ? user?.message : "-"}</td>
                          <td className="space-nowrap text-nowrap">
                            <Form className="switchbtn">
                              <Badge
                                bg={getBadgeColor(user?.campaign?.status)}
                                className={`text-capitalized`}>
                                {user?.campaign?.status === "pending"
                                  ? "draft"
                                  : user?.campaign?.status}
                              </Badge>
                            </Form>
                          </td>                          
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {wrapUpRequestListApprove && wrapUpRequestListApprove?.data?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Campaign Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {parseInt(wrapUpRequestListApprove?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};
export default CampaignsWrapUpRequestDone;
