/* eslint-disable */
import React, { useEffect } from "react";
import UserIcons from "../../assets/img/group.png";
import Fundcons from "../../assets/img/funding.png";
import Blogcons from "../../assets/img/blogger.png";
import AthleteIcon from "../../assets/img/athlete.png";
import CharityIcons from "../../assets/img/charity.png";
import ContactIcons from "../../assets/img/phone-call.png";
import { Image } from "react-bootstrap";
import Fundraisecons from "../../assets/img/brokerage.png";
import DonationsIcons from "../../assets/img/donation.png";
import GetDashboard from "../../Api/GetDashboard";
import CommonLogout from "../Common/CommonLogout";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardCount } from "../../redux/actions/dashboardAction";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { ADMIN_BLOG_MANAGEMENT_URL, ATHLETE_MANAGEMENT_URL, CAMPAIGN_MANAGEMENT_URL, CHARITY_MANAGEMENT_URL, CONTACT_MANAGEMENT_URL, DONATIONS_MANAGEMENT_URL, FUNDRAISE_APPLICATION_MANAGEMENT_URL, FUNDRSISERS_MANAGEMENT_URL, USER_MANAGEMENT_URL } from "../Shared/constant";

const DashBoard = () => {
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { dashboardDetails } = useSelector((state) => state.dashboardReducers);
    const Dispatch = useDispatch();
    useEffect(() => {
        if (accessToken) {
            fetchData();
        }
    }, [accessToken]);

    const fetchData = async () => {
        // Get dashboard count
        const dashboardList = await GetDashboard(accessToken);
        if (dashboardList?.status === 200) {
            Dispatch(getDashboardCount(dashboardList))
        } else if (dashboardList?.detail === "Token expired.") {
            CommonLogout();
        } else if (dashboardList?.detail === "User is inactive.") {
            CommonLogout();
        } else {
            // toast.error(categoryList.data.error);
        };
    };

    google.charts.load('current', {'packages':['corechart', 'bar']});
    google.charts.setOnLoadCallback(drawChart);
    google.charts.setOnLoadCallback(drawChartDonations);
    google.charts.setOnLoadCallback(drawChartFundraiseApplications);

    function drawChart() {
        var data = google.visualization.arrayToDataTable([
            ['Task', 'Hours per Day'],
            // ['Athlete applications', dashboardDetails?.fundraise_application_count?.athlete_applications],
            // ['Charity applications', dashboardDetails?.fundraise_application_count?.charity_applications],
            ['Unverified', dashboardDetails?.fundraise_application_count?.unverified],
            ['Verified', dashboardDetails?.fundraise_application_count?.verified]
        ]);

        var options = {
        //   title: 'Users'
        };
        var chart = new google.visualization.PieChart(document.getElementById('piechart'));
        chart.draw(data, options);
    };

    const getLastSixMonth = [];
    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var today = dashboardDetails?.past_sixmonth_donation_count?.past_six_month_count?.length > 0 ? new Date(dashboardDetails?.past_sixmonth_donation_count?.past_six_month_count[dashboardDetails?.past_sixmonth_donation_count?.past_six_month_count?.length - 1]?.month) : new Date();
    var d;
    
    var month;
    for(var i = 6; i >= 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        getLastSixMonth.push(`${month} 1, ${moment(d).format('YYYY')}`);
    };

    var EmptyMonthArray = [
        [getLastSixMonth[0].split(" ")[0], 0],
        [getLastSixMonth[1].split(" ")[0], 0],
        [getLastSixMonth[2].split(" ")[0], 0],
        [getLastSixMonth[3].split(" ")[0], 0],
        [getLastSixMonth[4].split(" ")[0], 0],
        [getLastSixMonth[5].split(" ")[0], 0],
        [getLastSixMonth[6].split(" ")[0], 0]
    ];

    function drawChartDonations() {
        const PastSixPamentArrays = [['Year', 'count']];
        if(dashboardDetails?.past_sixmonth_donation_count?.past_six_month_count?.length > 0) {
            getLastSixMonth.filter((data_, index) => {
                const CurrentBar = dashboardDetails?.past_sixmonth_donation_count?.past_six_month_count[index];
                if(CurrentBar !== undefined) {
                    PastSixPamentArrays.push([moment.utc(CurrentBar.month).local().format('MMM D, YYYY'), CurrentBar.count]);
                };
            });
        } else {
            getLastSixMonth?.filter((data_, index) => {
                const CurrentBar = EmptyMonthArray[index];
                PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
            });
        };

        var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
        
        var options = {
            chart: {
                // title: 'Company Performance',
                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
            }
        };
        var chart = new google.charts.Bar(document.getElementById('columnchart_material'));
        chart.draw(data, google.charts.Bar.convertOptions(options));
    };


    const getLastSixMonthNew = [];
    var monthNamesNew = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var todaysa = dashboardDetails?.past_sixmonth_fundraise_application_count?.past_six_month_count?.length > 0 ? new Date(dashboardDetails?.past_sixmonth_fundraise_application_count?.past_six_month_count[dashboardDetails?.past_sixmonth_fundraise_application_count?.past_six_month_count?.length - 1]?.month) : new Date();
    var ds;
    
    var monthss;
    for(var i = 6; i >= 0; i -= 1) {
        ds = new Date(todaysa.getFullYear(), todaysa.getMonth() - i, 1);
        monthss = monthNamesNew[ds.getMonth()];
        getLastSixMonthNew.push(`${monthss} 1, ${moment(ds).format('YYYY')}`);
    };

    function drawChartFundraiseApplications() {
        const PastSixPamentArrays = [['Year', 'count']];
        if(dashboardDetails?.past_sixmonth_fundraise_application_count?.past_six_month_count?.length > 0) {
            getLastSixMonthNew.filter((data_, index) => {
                const CurrentBar = dashboardDetails?.past_sixmonth_fundraise_application_count?.past_six_month_count[index];
                if(CurrentBar !== undefined) {
                    PastSixPamentArrays.push([moment.utc(CurrentBar.month).local().format('MMM D, YYYY'), CurrentBar.count]);
                };
            });
        } else {
            getLastSixMonthNew.filter((data_, index) => {
                const CurrentBar = EmptyMonthArray[index];
                PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
            });
        };

        var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
        
        var options = {
            chart: {
                // title: 'Company Performance',
                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
            }
        };
        var chart = new google.charts.Bar(document.getElementById('columnchart_material_New'));
        chart.draw(data, google.charts.Bar.convertOptions(options));
    };
    const AmountGet = (amountSum) =>{
        const parsedAmount = parseFloat(amountSum / 100);
        const formattedAmount = parsedAmount.toFixed(2);
        const finalFormattedAmount = Number(formattedAmount)?.toLocaleString();
        return finalFormattedAmount;
    }
    const DonationAmount = () =>{
        const Donations = dashboardDetails?.fundraise_donations_count;
        const amountSum = AmountGet(Donations?.total_donation_amount?.amount__sum);
        const amount__avg = AmountGet(Donations?.avg_donated_amount?.amount__avg);
        const amount__max = AmountGet(Donations?.max_donated_amount?.amount__max);
       

        return(
            <Link className="dashboardBlogs gradient-4" to={DONATIONS_MANAGEMENT_URL}>
                <h5>Donations</h5>
                <h1>${amountSum}</h1>
                <div className="activdeaciwrapper">
                    <div className="activeunactiovdiv">
                    Avg donated: <div>${amount__avg}</div>
                    </div>
                    <div className="activeunactiovdiv">
                    Max donated: <div>${amount__max}</div>
                    </div>
                </div>
                <Image src={Fundraisecons} alt="user icons" />
            </Link>
        )
    }

    return (
        <Scrollbars 
            style={{ height: "calc(100% - 0px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="dashboardcols">
                <Link className="dashboardBlogs gradient-1" to={USER_MANAGEMENT_URL}>
                    <h5>Total Users</h5>
                    <h1>{dashboardDetails?.users_count?.total}</h1>
                    <div className="activdeaciwrapper">
                        <div className="activeunactiovdiv">
                            Active: <div>{dashboardDetails?.users_count?.active}</div>
                        </div>
                        <div className="activeunactiovdiv">
                            Inactive: <div>{dashboardDetails?.users_count?.inactive}</div>
                        </div>
                    </div>
                    <Image src={UserIcons} alt="user icons" />
                </Link>
                <Link className="dashboardBlogs gradient-6" to={FUNDRAISE_APPLICATION_MANAGEMENT_URL}>
                    <h5>Total Campaign Applications</h5>
                    <h1>{dashboardDetails?.fundraise_application_count?.total}</h1>
                    <div className="activdeaciwrapper">
                        <div className="activeunactiovdiv">
                            Active: <div>{dashboardDetails?.fundraise_application_count?.active}</div>
                        </div>
                        <div className="activeunactiovdiv">
                            Inactive: <div>{dashboardDetails?.fundraise_application_count?.inactive}</div>
                        </div>
                        <div className="activeunactiovdiv">
                        Verified <div>{dashboardDetails?.fundraise_application_count?.verified}</div>
                        </div>
                        <div className="activeunactiovdiv">
                        Unverified <div>{dashboardDetails?.fundraise_application_count?.unverified}</div>
                        </div> 
                    </div>
                    <Image src={Fundcons} alt="user icons" />
                </Link>
               <Link className="dashboardBlogs gradient-2" to={CAMPAIGN_MANAGEMENT_URL}>
                    <h5>Total Campaigns</h5>
                    <h1>{dashboardDetails?.champaign_count?.total}</h1>
                    <div className="activdeaciwrapper">
                        <div className="activeunactiovdiv">
                        Active <div>{dashboardDetails?.champaign_count?.active}</div>
                        </div>
                        <div className="activeunactiovdiv">
                        Inactive <div>{dashboardDetails?.champaign_count?.inactive}</div>
                        </div>
                    </div>
                    <Image src={Blogcons} alt="user icons" />
                </Link>
                {<DonationAmount/>}
                <Link className="dashboardBlogs gradient-7" to={CONTACT_MANAGEMENT_URL}>
                    <h5>Total Contact List</h5>
                    <h1>{dashboardDetails?.contact_us_count?.total}</h1>
                    <div className="activdeaciwrapper">
                        <div className="activeunactiovdiv">
                        Active: <div>{dashboardDetails?.contact_us_count?.active}</div>
                        </div>
                        <div className="activeunactiovdiv">
                        Inactive: <div>{dashboardDetails?.contact_us_count?.inactive}</div>
                        </div>
                    </div>
                    <Image src={ContactIcons} alt="user icons" />
                </Link>
                <Link className="dashboardBlogs gradient-8"  to={DONATIONS_MANAGEMENT_URL}>
                    <h5>Total Donations</h5>
                    <h1>{dashboardDetails?.fundraise_donations_count?.total}</h1>
                    <div className="activdeaciwrapper">
                        <div className="activeunactiovdiv">
                            Anonymous Users: <div>{dashboardDetails?.fundraise_donations_count?.anonymous_users}</div>
                        </div>
                        <div className="activeunactiovdiv">
                            Known Users: <div>{dashboardDetails?.fundraise_donations_count?.known_users}</div>
                        </div>
                    </div>
                    <Image src={DonationsIcons} alt="user icons" />
                </Link>
                <div className="dahsbordTablecol" style={{ width: "100%" }}>
                    <h4>Campaign Applications</h4>
                    <div id="piechart" className="piechartwraps" style={{ width: '100%', height: '300px' }}></div>
                </div>
                <div className="colsrow">

                    <div className="dahsbordTablecol w-100">
                        <h4>Mostly Donated Campaigns</h4>
                        <div className="tableswrps">
                            <Scrollbars 
                                style={{ maxHeight: "calc(100%)"}} 
                                className="ScrollbarsWrapper"
                                renderView={props => <div {...props} className="view"/>}
                            >
                                <div className="table-responsive">
                                    <table className="table" style={{ marginBottom: "0px" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Campaign Name</th>
                                                <th>Donation count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {/* because of api new development api change  this section getting error =hide it */}
                                            {/* {dashboardDetails?.champaign_count?.mostly_donated_champaign && dashboardDetails?.champaign_count?.mostly_donated_champaign?.map((user, index) => {
                                                return(<tr key={index
                                                }>
                                                    <td>{index + 1}</td>
                                                    <td>{user?.title}</td>
                                                    <td>{user?.id__count}</td>
                                                </tr>)
                                            })} */}
                                            {/* {dashboardDetails?.champaign_count?.mostly_donated_champaign?.length === 0 && (<tr><td style={{ textAlign: "center" }} colSpan={7}>Admins fundraise not found..</td></tr>)} */}
                                        </tbody>
                                    </table>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
                <div className="dahsbordTablecol" style={{ width: "100%" }}>
                    <h4>Past Six Month Donations:</h4>
                    <div id="columnchart_material" className="piechartwraps" style={{ width: '100%', height: '300px' }}></div>
                </div>
                <div className="dahsbordTablecol" style={{ width: "100%" }}>
                    <h4>Past Six Month Campaign Applications:</h4>
                    <div id="columnchart_material_New" className="piechartwraps" style={{ width: '100%', height: '300px' }}></div>
                </div>
            </div>
            {/* <h1>hi</h1> */}
        </Scrollbars>);
}

export default DashBoard;