import React from "react";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import DefaultImage from "../../../assets/img/DefaultProduct.jpg";
import LoaderImage from "../../../assets/img/spinner_transferent.svg";
import UploadImageIcon from "../../../assets/img/uploadImage.png";



const FileUploader = ({
  label,
  name,
  type,
  value, 
  onUpload,
  onDelete,
  isMultiple = false,
  uploadFileLoader =false,
  error = false,
  maxFileSize = 5 * 1024 * 1024,  // 5MB,
}) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Validate file type
    const validFormats = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
    if (!validFormats.includes(file.type)) {
      toast.error("Invalid file type. Please select a JPG, PNG, or WEBP file.");
      return;
    }

    // Validate file size
    if (file.size > maxFileSize) {
      toast.error("File size should not exceed 5MB");
      return;
    }

    onUpload(file,e.target?.dataset?.index);
  };
  return (
    <>
<div className="row">
      <div className="col-md-4">
        <div className="formgroups">
          <h4 className="font-size">{label}</h4>
        </div>
      </div>
      <div className="col-md-8">
        <Form.Group className="mb-3">
         {(value !== undefined && value !== "" && !isMultiple && value!==null) ? (<div className="imageuploads">
            <Image src={value?.file_url_400} alt="charity avatar" />
                <span className="closeIcon" onClick={() => onDelete()}></span>
            </div>):
          <div className="uploadbuttons me-2 mb-2" onClick={() => document.getElementById(name)?.click()}>
            <div className={uploadFileLoader ? "fileloader active" : "fileloader"}>
              {uploadFileLoader ? (
                <Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />
              ) : (
                <>
                  <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                  <input
                    type="file"
                      id={name}
                      className="uploadfilInput"
                      onChange={(e) => handleFileChange(e)} 
                      accept=".svg, .png, .jpg, .jpeg, .webp"
                      multiple={isMultiple ? true : false}
                  />
                </>
              )}
            </div>
            <div className="uploadfilebox">
              <Image src={DefaultImage} alt="upload image" />
            </div>
          </div>
         }

          {isMultiple ? (
            <div className="d-flex flex-wrap">
              {(Array.isArray(value) ? value : [])?.map((item, index) => (
                <div key={index} className="imageuploads bg-grey me-2 mb-2">
                  <Image src={item?.file_url_400} alt="uploaded" />
                  <span className="closeIcon" onClick={() => onDelete(index)}></span>
                </div>
              ))}
            </div>
          ) :null}
          {error && <div className="danger-colorset mt-1">{error}</div>}
        </Form.Group>
      </div>
    </div>

   
    </>
  );
};

export default FileUploader;
