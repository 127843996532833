import React from "react";

const FormSelectCustome = ({ 
  label, 
  name, 
  value, 
  options, 
  onChange, 
  error, 
  required 
}) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <h4 className={required ? "label-required font-size" : "font-size"}>{label}</h4>
      </div>
      <div className="col-md-8">
        <div className="mb-3">
          <select
            className="form-control"
            name={name}
            value={value}
            onChange={onChange}
            aria-label={label}
          >
            <option value="" disabled>
              Select Type
            </option>
            {options?.map((elm, index) => (
              <option key={index} value={elm?.value}>
                {elm?.label}
              </option>
            ))}
          </select>
          <div style={{height:'15px'}}>
          {error && <div className="text-danger" style={{fontSize:'12px'}}>{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSelectCustome;
