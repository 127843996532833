/* eslint-disable no-mixed-operators */

import { toast } from "react-toastify";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { setStoreBlogListData, setStoreSponsorsListData } from "../../redux/actions/campaignAction";
import { BLOG_LIST_API_URL, LIST_SETTING_MODULE } from "./constant";

/* eslint-disable eqeqeq */
export function uuidV4() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
export const getSettingModuleList = () =>(async (dispatch, getState)=>{
    const {accessToken} =  getState()?.personalReducers;
    const response = await GetRequestCallAPI(`${LIST_SETTING_MODULE}`, accessToken );
    if(response?.status === 200) {
        dispatch(setStoreSponsorsListData(response?.data?.data?.data));
    } else if(response?.status === 403) {
        dispatch(setStoreSponsorsListData({facefunding_sponsors:[]}));
    } else {
        dispatch(setStoreSponsorsListData({facefunding_sponsors:[]}));
    };
})

export const getBlogListData = (userType, page, limit, search) =>(async (dispatch, getState)=>{
    const {accessToken} =  getState()?.personalReducers;
    const response = await GetRequestCallAPI(`${BLOG_LIST_API_URL}/${userType}/${limit}/${page}/${search == "" ?0:search}`, accessToken );
    if(response?.status === 200) {
        dispatch(setStoreBlogListData(response?.data));
    } else if(response?.status === 403) {
        dispatch(setStoreBlogListData());
    } else {
        dispatch(setStoreBlogListData());
    };
})

export const AuthenticateResponse = (response, state, dataReturn) => {
    console.log("response", response);
    const errorData = {};
  
    if (response?.status == 400) {
      if (typeof response?.data?.error === "string") {
        if (dataReturn) {
          errorData.error = response?.data?.error;
        } else {
          toast.error(response?.data?.error);
        }
      } else if (typeof response?.data?.error === "object") {
        for (const [key, value] of Object.entries(response?.data?.error)) {
          if (dataReturn) {
            errorData[key] = value;
          } else {
            toast.error(`${key}: ${value}`);
          }
        }
      }
  
      if (typeof response?.msg === "object") {
        if (response?.msg.mobile) {
          if (dataReturn) {
            errorData.mobile = response?.msg.mobile;
          } else {
            toast.error(response?.msg.mobile);
          }
        }
      } else if (typeof response?.msg === "string") {
        if (dataReturn) {
          errorData.mobile = response?.msg;
        } else {
          toast.error(response?.msg);
        }
      }
    } else if (response?.status == 403) {
      if (typeof response?.data?.error === "string") {
        if (dataReturn) {
          errorData.error = response?.data?.error;
        } else {
          toast.error(response?.data?.error);
        }
        if (state.redirect) {
          return state.redirect();
        }
      } else if (typeof response?.data?.error === "object") {
        for (const [key, value] of Object.entries(response?.data?.error)) {
          if (dataReturn) {
            errorData[key] = value;
          } else {
            toast.error(`${key}: ${value}`);
          }
        }
      }
    }
  
    if (dataReturn) {
      return errorData;
    }
  };


  export const getStatusColor = (status) => {
    switch (status) {
        case "pending":  
            return "#6c757d";   //#6c757d  FFCC00
        case "review":
            return "#e5b177"; //e5b177  FF9500
        case "complete":
            return "#007AFF"; 
        case "approved":
            return "#A4C0AA"; //#A4C0AA 34c759
        case "rejected":    
            return "#C34330";  //ff3b30
        case "requested":
            return "#AF52DE";
        default:
            return "#C7C7CC"; 
    }
};

export const getBadgeColor = (status) => {
  switch (status?.toLowerCase()) {
      case "pending":
          return "pending"; 
      case "review":
          return "review";
      case "complete":
          return "complete";
      case "approved":
          return "approved";
      case "rejected":
          return "rejected";
      case "requested":
          return "requested";
      case "deleted":
          return "delete";
      default:
          return "light"; 
  }
};

const checkPasswordValidity = (value, label = "Password") => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return `${label} must not contain Whitespace.`;
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    return `${label} must have at least one Uppercase Character.`;
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    return `${label} must have at least one Lowercase Character.`;
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    return `${label} must contain at least one Digit.`;
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    return `${label} must contain at least one Special Symbol.`;
  }

  const isValidLength = /^.{8,16}$/;
  if (!isValidLength.test(value)) {
    return `${label} must be 8-16 Characters Long.`;
  }

  return null;
};
export const validateField = (value, rules) => {
  let error = "";
  if (rules?.password) {
    error = checkPasswordValidity(value, rules?.label);
  } else {
    // Check for pattern match
    if ((rules?.required || value) && rules?.pattern && !rules?.pattern?.test?.(value) && value!="") {
      error = `Invalid ${rules?.label}`;
    }
  }

  // Check if the field is required and empty
  if (rules?.required && (value === null || value === "" || value === undefined || (rules?.isMultiple && !value?.length > 0))) {
    error = rules?.title ?? `${rules?.title ?? rules?.label} is required`;
  }

  // Check for minLength
  if (rules?.minLength && value?.length < rules?.minLength) {
    error = `${rules?.title ?? rules?.label} must be at least ${rules?.text??""}${rules?.minLength} ${rules?.numberTitle? rules?.numberTitle : rules?.number ?"digit" :"characters"}`;
  }
  // Check for minDigit
  if (rules?.minDigit && value < rules?.minDigit) {
    error = `${rules?.title ?? rules?.label} must be at least ${rules?.text??""}${rules?.minDigit} ${rules?.numberTitle? rules?.numberTitle : rules?.number ?"digit" :"characters"}`;
  }
  // Check for minDigit
  if (rules?.maxDigit && value > rules?.maxDigit) {
    error = `${rules?.title ?? rules?.label} must be no more than ${rules?.text??""}${rules?.maxDigit} ${rules?.numberTitle? rules?.numberTitle : rules?.number ?"digit" :"characters"}`;
  }
  // Check for maxLength
  if (rules?.maxLength && value?.length > rules?.maxLength) {
    error = `${rules?.title ?? rules?.label} must be no more than ${rules?.text??""}${rules?.maxLength} ${rules?.numberTitle? rules?.numberTitle : rules?.number ?"digit" :"characters"}`;
  }
  // Check for maxLength

  if (rules?.validate) {
    error = rules?.validate(value);
    if(!error){
      error = ""
    }
  }
  return error;
};


export function uuidv4() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const getPayloadCampaignAdd = (formData) =>{
  const payload = {
    avatar_validation:true,
    web_appearance:true,
    sequence_no:"",
    title:"",
    subtitle:"",
    description:"",
    start_date:null,
    end_date:null,
    campaign_url:"",
    details:{
      campaign_type: "",
      campaign_mail_id: "",
      request_id: "",
      non_profit: "",
      ein_number: "",
      identify_non_profit: "",
      campaign_start_date:"",
      campaign_end_date:"",
      receive_mosaic_earlier:"",
      need_mosaic_by:"",
      mosaic_display_method:"",
      mosaic_display_option:"",
      source_image: null,
      campaign_goal:"",
      financial_progress:formData?.financial_progress,
      contributions_key_type:"",
      contributions_value_type:"",
      manage_campaign:"",
      fundraising_campaign:"",
      partner_with_non_profit:"",
      campaign_users:[],
      campaign_story:"",
      campaign_title:"",
      campaign_sub_title:"",
      campaign_description:"",
      campaign_file_type:"",
      campaign_file_source:"",
      campaign_second_file_type:"",
      campaign_second_file_source:"",
      campaign_second: formData?.campaign_second,
      company_website:"",
      company_title:"",
      company_description:"",
      company_file_type:"",
      company_file_source:"",
      non_profit_website:"",
      non_profit_title:"",
      non_profit_description:"",
      non_profit_file_type:"",
      non_profit_file_source:"",
      supporting_sponsors:"",
      supporting_sponsors_campaign:"",
      sponsor_signature:"",
      sponsor_logo:"",
      signature_logo:[],
      upload_slogan:"",
      supporting_sponsor_logos:[],
      email_subject:"",
      email_body:"",
      attack_signature_sponsor_logos: formData?.attack_signature_sponsor_logos,
      privacy_policy: formData?.privacy_policy,
      terms_conditions: formData?.terms_conditions,
      percentage:0
    }
  }
  if(formData?.status === "review"){
    payload.status = formData?.status;
  }
  if(formData?.campaign_start_date){
    payload.start_date = formData?.campaign_start_date;
  }
  if(formData?.avatar_validation){
    payload.avatar_validation = formData?.avatar_validation;
  }
  if(formData?.web_appearance){
    payload.web_appearance = formData?.web_appearance;
  }
  if(formData?.sequence_no){
    payload.sequence_no = formData?.sequence_no;
  }
  if(formData?.campaign_end_date){
    payload.end_date = formData?.campaign_end_date;
  }
  if(formData?.campaign_title){
    payload.title = formData?.campaign_title;
  }
  if(formData?.campaign_sub_title){
    payload.subtitle = formData?.campaign_sub_title;
  }
  if(formData?.campaign_description){
    payload.description = formData?.campaign_description;
  }
  if(formData?.campaign_type){
    payload.details.campaign_type = formData?.campaign_type;
  }
  if(formData?.campaign_mail_id){
    payload.details.campaign_mail_id = formData?.campaign_mail_id;
  }
  if(formData?.request_id){
    payload.details.request_id = formData?.request_id;
  }
  if(formData?.percentage){
    payload.details.percentage = formData?.percentage;
  }
  if(formData?.non_profit){
    payload.details.non_profit = formData?.non_profit;
  }
  if(formData?.ein_number){
    payload.details.ein_number = formData?.ein_number;
  }
  if(formData?.identify_non_profit){
    payload.details.identify_non_profit = formData?.identify_non_profit;
  }
  if(formData?.receive_mosaic_earlier){
    payload.details.receive_mosaic_earlier = formData?.receive_mosaic_earlier;
  }
  if(formData?.need_mosaic_by){
    payload.details.need_mosaic_by = formData?.need_mosaic_by;
  }
  if(formData?.mosaic_display_method){
    payload.details.mosaic_display_method = formData?.mosaic_display_method;
  }
  if(formData?.mosaic_display_option){
    payload.details.mosaic_display_option = formData?.mosaic_display_option;
  }
  if(formData?.source_image){
    payload.details.source_image = formData?.source_image;
  }
  if(formData?.campaign_goal){
    payload.details.campaign_goal = formData?.campaign_goal;
  }
  if(formData?.contributions_key_type){
    payload.details.contributions_key_type = formData?.contributions_key_type;
  }
  if(formData?.contributions_value_type){
    payload.details.contributions_value_type = formData?.contributions_value_type;
  }

  if(formData?.manage_campaign){
    payload.details.manage_campaign = formData?.manage_campaign;
  }
  if(formData?.fundraising_campaign){
    payload.details.fundraising_campaign = formData?.fundraising_campaign;
  }
  if(formData?.partner_with_non_profit){
    payload.details.partner_with_non_profit = formData?.partner_with_non_profit;
  }
  if(formData?.campaign_users){
    payload.details.campaign_users = formData?.campaign_users;
  }
  if(formData?.campaign_story){
    payload.details.campaign_story = formData?.campaign_story;
  }
  if(formData?.campaign_file_type){
    payload.details.campaign_file_type = formData?.campaign_file_type;
  }
  if(formData?.campaign_file_source){
    payload.details.campaign_file_source = formData?.campaign_file_source;
  }
  if(formData?.campaign_second_file_type){
    payload.details.campaign_second_file_type = formData?.campaign_second_file_type;
  }
  if(formData?.campaign_second_file_source){
    payload.details.campaign_second_file_source = formData?.campaign_second_file_source;
  }
  if(formData?.company_website){
    payload.details.company_website = formData?.company_website;
  }
  if(formData?.company_title){
    payload.details.company_title = formData?.company_title;
  }
  if(formData?.company_description){
    payload.details.company_description = formData?.company_description;
  }
  if(formData?.company_file_type){
    payload.details.company_file_type = formData?.company_file_type;
  }
  if(formData?.company_file_source){
    payload.details.company_file_source = formData?.company_file_source;
  }
  if(formData?.non_profit_website){
    payload.details.non_profit_website = formData?.non_profit_website;
  }
  if(formData?.non_profit_title){
    payload.details.non_profit_title = formData?.non_profit_title;
  }
  if(formData?.non_profit_description){
    payload.details.non_profit_description = formData?.non_profit_description;
  }
  if(formData?.non_profit_file_type){
    payload.details.non_profit_file_type = formData?.non_profit_file_type;
  }
  if(formData?.non_profit_file_source){
    payload.details.non_profit_file_source = formData?.non_profit_file_source;
  }
  if(formData?.supporting_sponsors){
    payload.details.supporting_sponsors = formData?.supporting_sponsors;
  }
  if(formData?.supporting_sponsors_campaign){
    payload.details.supporting_sponsors_campaign = formData?.supporting_sponsors_campaign;
  }
  if(formData?.sponsor_signature){
    payload.details.sponsor_signature = formData?.sponsor_signature;
  }
  if(formData?.sponsor_logo){
    payload.details.sponsor_logo = formData?.sponsor_logo;
  }
  if(formData?.signature_logo){
    payload.details.signature_logo = formData?.signature_logo;
  }
  if(formData?.upload_slogan){
    payload.details.upload_slogan = formData?.upload_slogan;
  }
  if(formData?.supporting_sponsors_campaign){
    payload.details.supporting_sponsors_campaign = formData?.supporting_sponsors_campaign;
  }
  if(formData?.supporting_sponsor_logos){
    payload.details.supporting_sponsor_logos = formData?.supporting_sponsor_logos;
  }
  if(formData?.email_subject){
    payload.details.email_subject = formData?.email_subject;
  }
  if(formData?.email_body){
    payload.details.email_body = formData?.email_body;
  }
  return payload;
}


export const getVimeoVideoId = (url) => {
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
  const match = url?.match(vimeoRegex);
  if (match && match[2]) {
    return match[2];
  }
  return null;
};
export const callPreviewLink = (CharityYoutubevideo) =>{
  const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

  const VimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
  if(YouTubeRegex.test(CharityYoutubevideo) === true) {
      return <iframe src={`https://www.youtube.com/embed/${CharityYoutubevideo?.split("/")[CharityYoutubevideo?.split("/").length - 1].replace("watch?v=", "")}`}></iframe>
  }
  const vimeoLink = getVimeoVideoId(CharityYoutubevideo)
  if(VimeoRegex.test(CharityYoutubevideo) === true && vimeoLink) {
      return <iframe src={`https://player.vimeo.com/video/${vimeoLink}`}></iframe>
  }
}
function extractVideoId(url) {
  // const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/shorts\/)([^"&?\/\s]{11})/;

  const match = url.match(youtubeRegex);
  return match ? match[1] : null;
}
const isYouTubeShorts = (url) => {
  const shortsPattern = /youtube\.com\/shorts\/([^"&?\/\s]{11})/;
  return shortsPattern.test(url);
};
const YOUTUBE_API_KEY = "AIzaSyCyC5SlXK3MUu2kmHcM05rTar9gSVUpa50"
export const validateYouTubeVideo = async (url) => {
  const videoId = extractVideoId(url);
  if (!videoId) {
    return { url, status: 400, message: "Invalid YouTube URL" };
  }

  try {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet,status`
    );

    const data = await response.json();
    if (data.items.length === 0) {
      throw new Error("Video not found");
    }

    return {
      url,
      status: 200,
      message: "YouTube video found",
      title: data.items[0].snippet.title,
      thumbnail_url: data.items[0].snippet.thumbnails.high.url,
    };
  } catch (error) {
    return { url, status: 400, message: "YouTube video not found" };
  }
};
export const validateYtAndVimeo = async (url) => {
  const videoId = extractVideoId(url);
  const youtubeShorts = isYouTubeShorts(url);
  if(youtubeShorts){
    const response = await validateYouTubeVideo(url);
    return response;
  }else if (videoId) {
    try {
      const response = await fetch(`https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${videoId}`);
      
      if (response.status === 404) {
        throw new Error("Not Found");
      }

      if (!response.ok) {
        throw new Error("API error");
      }
      const res = await response?.json();
      return {
        url: url,
        status: 200,
        platform: "YouTube",
        message: "Video Found",
        thumbnail_url: res?.thumbnail_url
      };
    } catch (error) {
      return {
        url: url,
        status: 400,
        platform: "YouTube",
        message: "YouTube video not found",
      };
    }
  } else {
    try {
      const checkVimeoVideo = await fetch(`/api/validate-vimeo?url=${encodeURIComponent(url)}`);
      
      if (!checkVimeoVideo.ok) {
        throw new Error("Vimeo video not found");
      }
      const res = await checkVimeoVideo?.json();

      return {
        url: url,
        status: 200,
        platform: "Vimeo",
        message: "Video Found",
        thumbnail_url: res?.thumbnail_url,
      };
    } catch (error) {
      return {
        url: url,
        status: 400,
        platform: "Vimeo",
        message: "Vimeo video not found",
      };
    }
  }
};